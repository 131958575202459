<div class="d-flex mb-4">
  <div class="flex-grow-1 me-2">
    Add Job
    <span>
      <span *ngIf="selectedStatus?.id"> > {{ selectedStatus?.name }}</span>

      <span
        *ngIf="otherJobType?.st?.data?.id"
        (click)="currentStep = 1; editField('st')"
      >
        >
        <span class="cursor-pointer text-decoration-underline">{{
          otherJobType.st.value | misc : "sliceWord" : 10
        }}</span>
      </span>
      <span
        *ngIf="otherJobType?.site?.data?.id"
        (click)="currentStep = 1; editField('site')"
      >
        >
        <span class="cursor-pointer text-decoration-underline"
          >{{ otherJobType.site.value }} [{{ otherJobType.client.value }}]</span
        >
      </span>

      <span
        *ngIf="otherJobType?.assignee?.data?.id"
        (click)="currentStep = 2; editField('assignee')"
      >
        >
        <span class="cursor-pointer text-decoration-underline">{{
          otherJobType?.assignee?.value | misc : "sliceWord" : 10
        }}</span>
      </span>

      <span
        *ngIf="otherJobType.ai.value"
        (click)="currentStep = 3; shiftFocus('aiId')"
      >
        >
        <span class="cursor-pointer text-decoration-underline">{{
          otherJobType.ai.value | misc : "sliceWord" : 10
        }}</span>
      </span>
    </span>
  </div>
</div>

<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <form [formGroup]="formdata">
      <!-- Step 1: Select Site -->
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              [routerLink]="['/roster']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!otherJobType.site.data"
              (click)="
                currentStep = 2; shiftFocus('assigneeId'); getAssignees()
              "
            >
              Next
            </button>
          </div>
        </div>

        <div *ngIf="selectedStatus?.type_identity === 1" class="mb-2">
          <div *ngIf="otherJobType.st.data?.id && !otherJobType.st?.search">
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Service Type</dt>
                  <dd>
                    {{ otherJobType.st.value }}
                  </dd>
                </div>
                <div>
                  <span (click)="editField('st')">
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i
                  ></span>
                </div>
              </div>
            </dl>
          </div>
          <div *ngIf="!otherJobType.st.data?.id || otherJobType.st?.search">
            <gtapp-auto-complete
              [data]="serviceTypeListData"
              placeHolder="Service Type (Optional)"
              searchKeyword="name"
              [initialValue]="otherJobType?.st?.value"
              [itemTemplate]="serviceTypeTemplate"
              [addInitialKey]="isAdmin && otherJobType?.st?.tempValue"
              (inputChanged)="onSTSearch($event)"
              (selected)="onServiceTypeSelect($event)"
              (blurTriggered)="blurTriggered('st')"
              [enableCloseOption]="true"
              (fieldCleared)="editField('st')"
              [inputId]="'stId'"
            >
            </gtapp-auto-complete>
            <ng-template #serviceTypeTemplate let-item>
              @if (item?.key === 0) {
              <div>Add Service Type</div>
              } @else {
              <div>
                {{ item.name }}
              </div>
              }
            </ng-template>
          </div>
        </div>

        <div *ngIf="otherJobType.site.data?.id && !otherJobType.site?.search">
          <dl>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Site</dt>
                <dd>
                  {{ otherJobType.site.value }}
                  <div>[{{ otherJobType.client.value }}]</div>
                </dd>
              </div>
              <div>
                <span (click)="editField('site')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
          </dl>
        </div>
        <div *ngIf="!otherJobType.site.data?.id || otherJobType.site?.search">
          <gtapp-auto-complete
            [data]="siteList"
            placeHolder="Select Site"
            searchKeyword="company_name"
            [initialValue]="formdata.value.siteName"
            [itemTemplate]="siteTemplate"
            [addInitialKey]="true"
            (inputChanged)="onSiteSearch($event)"
            (selected)="onSelectSite($event)"
            (blurTriggered)="blurTriggered('site')"
            [enableCloseOption]="true"
            (fieldCleared)="editField('site')"
            [inputId]="'siteId'"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div class="fw-medium">
              {{ item?.company_name }} [{{ item?.company?.company_name }}]
            </div>

            <div class="small-font text-secondary">
              {{ item?.address?.full_address }}
            </div>
          </div>
          }
        </ng-template>
      </ng-container>

      <!-- Step 2: Assign to Guard -->
      <ng-container *ngIf="currentStep === 2">
        <div class="row row-cols-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 1; assigneeList = []"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!formdata.value.assigneeName"
              (click)="currentStep = 3"
            >
              Next
            </button>
          </div>
        </div>

        <ng-container *ngIf="selectedStatus.enable_assignees === true">
          <div
            *ngIf="
              otherJobType.assignee.data?.id && !otherJobType.assignee?.search
            "
          >
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Assigned To</dt>
                  <dd>
                    {{ formdata.value.assigneeName }}
                  </dd>
                </div>
                <div>
                  <span (click)="editField('assignee')">
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i
                  ></span>
                </div>
              </div>
            </dl>
          </div>
          <div
            *ngIf="
              !otherJobType.assignee.data?.id || otherJobType.assignee?.search
            "
          >
            <gtapp-auto-complete
              [data]="assigneeList"
              placeHolder="Assign To"
              searchKeyword="full_name"
              [initialValue]="formdata.value.assigneeName"
              [itemTemplate]="assigneeTemplate"
              (inputChanged)="onAssigneeSearch($event)"
              (selected)="onAssigneeSelect($event)"
              (blurTriggered)="blurTriggered('assignee')"
              [enableCloseOption]="true"
              (fieldCleared)="editField('assignee')"
              [inputId]="'assigneeId'"
            >
            </gtapp-auto-complete>
            <ng-template #assigneeTemplate let-item>
              <div>
                {{ item?.full_name }}
                <small *ngIf="!item?.validStateLicense">
                  <i class="fa-solid fa-shield-halved"></i>
                </small>
                <span
                  *ngIf="item?.interval_check > 0"
                  class="small-font text-info"
                >
                  [Welfare check on every
                  {{ item?.interval_check }} mins.]</span
                >
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

      <!-- Step 3: Additional Info -->
      <ng-container *ngIf="currentStep === 3">
        <div class="row row-cols-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="currentStep = 4; formatScheduleForm()"
            >
              Next
            </button>
          </div>
        </div>

        <div>
          <textarea
            id="aiId"
            class="form-control"
            placeholder="Additional Information"
            cols="20"
            rows="10"
            wrap="hard"
            maxlength="900"
            style="width: 100%"
            type="text"
            [(ngModel)]="otherJobType.ai.value"
            formControlName="requestDetail"
          ></textarea>
        </div>
      </ng-container>

      <!-- Step 4: Geofence Alert -->
      <ng-container *ngIf="currentStep === 4">
        <div class="row row-cols-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 3"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="formatScheduleForm(); currentStep = 5"
            >
              Next
            </button>
          </div>
        </div>
        <div>
          <button
            class="btn btn-danger btn-lg w-100 mb-2"
            (click)="
              enableGeofencing = !enableGeofencing;
              additionalInfo.geofence_distance = 0
            "
          >
            {{ enableGeofencing ? "Remove Geofence" : "Add Geofence" }}
          </button>
        </div>
        @if(enableGeofencing){
        <ng-container>
          <gtapp-map-leaflet
            [latLon]="{
              lat:
                otherJobType.site?.data?.address?.latitude ||
                otherJobType?.site?.data?.gps?.lat,
              lon:
                otherJobType.site?.data?.address?.longitude ||
                otherJobType?.site?.data?.gps?.lon
            }"
            mapId="smallMapOtherJob"
            [changableRadius]="true"
            [mapRadiusParams]="{
              minKm: 0,
              maxKm: 5000,
              nearestKmValue: 0
            }"
            (updateMinDistance)="updateMinDistance($event)"
          ></gtapp-map-leaflet>
        </ng-container>
        }@else {
        <ng-container>
          <gtapp-map-leaflet
            [latLon]="{
              lat:
                otherJobType.site?.data?.address?.latitude ||
                otherJobType?.site?.data?.gps?.lat,
              lon:
                otherJobType.site?.data?.address?.longitude ||
                otherJobType?.site?.data?.gps?.lon
            }"
            mapId="smallMapOtherJobNormal"
          ></gtapp-map-leaflet> </ng-container
        >}
      </ng-container>

      <!-- Step 5: Schedule Time? -->
      <ng-container *ngIf="currentStep === 5">
        <div class="row row-cols-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 4"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              (click)="onAddJob()"
              [disabled]="addJobPressed || !schedulerForm.valid"
            >
              Add Job
            </button>
          </div>
        </div>

        <gtapp-scheduler
          *ngIf="scheduleStepperActive"
          [dialogBox]="false"
          [schedulerForm]="schedulerForm"
          [editSchedule]="true"
        ></gtapp-scheduler>
        <div class="mt-4 form-floating">
          <select
            class="form-select"
            (click)="getIntervalData()"
            [ngModel]="intervalData[0]?.value"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateInterval($event)"
            placeholder="Welfare Check Interval"
          >
            <option
              *ngFor="let interval of intervalData"
              [ngValue]="interval.value"
            >
              {{ interval.name }}
            </option>
          </select>
          <label for="">Welfare Check Interval</label>
        </div>
        <div *ngIf="intervalCheck" class="alert">
          <div>
            Guards will be reminded to submit a welfare check
            <span class="fw-bold">
              {{ welfareReminderDict?.[intervalCheck]  }} minutes before</span
            >
            the interval set above.
          </div>
          <div>
            Maximum inactivity time allowed before a missed welfare check is
            recorded:
            <span class="fst-italic fw-bold">
              {{ intervalCheck }} minutes.
            </span>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div
    class="col"
    *ngIf="
      (otherJobType.site?.data?.address?.latitude ||
        otherJobType?.site?.data?.gps?.lat) &&
      largeView
    "
  >
    <div>
      <button
        class="btn btn-danger btn-lg w-50 mb-2"
        (click)="
          enableGeofencing = !enableGeofencing;
          additionalInfo.geofence_distance = 0
        "
      >
        {{ enableGeofencing ? "Remove Geofence" : "Add Geofence" }}
      </button>
    </div>
    @if(enableGeofencing){
    <ng-container>
      <gtapp-map-leaflet
        [latLon]="{
          lat:
            otherJobType.site?.data?.address?.latitude ||
            otherJobType?.site?.data?.gps?.lat,
          lon:
            otherJobType.site?.data?.address?.longitude ||
            otherJobType?.site?.data?.gps?.lon
        }"
        mapId="largeMapOtherJob"
        [changableRadius]="true"
        [mapRadiusParams]="{
          minKm: 0,
          maxKm: 5000,
          nearestKmValue: 0
        }"
        (updateMinDistance)="updateMinDistance($event)"
      ></gtapp-map-leaflet>
    </ng-container>
    }@else {
    <ng-container>
      <gtapp-map-leaflet
        [latLon]="{
          lat:
            otherJobType.site?.data?.address?.latitude ||
            otherJobType?.site?.data?.gps?.lat,
          lon:
            otherJobType.site?.data?.address?.longitude ||
            otherJobType?.site?.data?.gps?.lon
        }"
        mapId="largeMapOtherJobNormal"
      ></gtapp-map-leaflet> </ng-container
    >}
  </div>
</div>
<!-- License Reminder Check Template -->
<ng-template
  #licenceCheckTemplateRef
  let-data
  let-licenceCheckTemplateRef="dialogRef"
>
  <div class="card dialog-box">
    <div class="card-header">
      WARNING

      <span
        class="float-end"
        (click)="licenceCheckTemplateRef.close(); shiftFocus('assigneeId')"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div>
      <div class="alert alert-danger">
        You have not added any security licences for
        {{ otherJobType?.site?.data?.address?.state_code }}

        <div class="mt-2">
          Licences can be added in
          <a
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
            routerLink="/company-profile"
            class="link-primary"
          >
            Company Profile
          </a>
        </div>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="licenseCheckbox"
          (change)="licenseReminderField = !licenseReminderField"
        />
        <label class="form-check-label" for="licenseCheckbox">
          Don't show this again
        </label>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-secondary btn-lg w-100"
            (click)="
              ignoreLicenseCheck();
              licenceCheckTemplateRef.close();
              shiftFocus('assigneeId')
            "
          >
            Ignore
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="showLicensePopup(); licenceCheckTemplateRef.close()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
