<div class="card dialog-box">
  <div class="card-header">
    {{ scheduleData ? "Edit" : "Add" }} Patrol Report

    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <ng-container *ngIf="currentStep === 1">
    <form [formGroup]="schedulerForm">
      <div class="row row -cols-1 g-3">
        <div class="form-floating">
          <input
            class="form-control"
            formControlName="name"
            type="text"
            placeholder="Description"
            required
            id="description"
          />
          <label for="description">Description</label>
        </div>

        <div class="d-flex justify-content-center my-2">
          <div
            class="form-check form-check-inline"
            *ngFor="let option of repeatTypes"
          >
            <input
              class="form-check-input"
              type="radio"
              formControlName="repeat_type"
              [value]="option.value"
              id="{{ option.value }}"
            />
            <label class="form-check-label" for="{{ option.value }}">{{
              option.label
            }}</label>
          </div>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            type="time"
            formControlName="mail_time"
            pattern="[0-9]{2}:00"
            title="Enter time in HH:00 format"
            id="mail_time"
            step="3600"
            (change)="formatMailTime($event)"
          />
          <label for="mail_time">Time of Day</label>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            type="date"
            formControlName="start_day"
            id="start_day"
            placeholder="Start Date"
            [min]="startDate"
            [readOnly]="scheduleData"
            [attr.disabled]="scheduleData"
          />
          <label for="start_day">Start Date</label>
        </div>
        <div class="col">
          <label for="user">
            <input
              class="form-check-input"
              type="checkbox"
              id="user"
              formControlName="summary_only"
            />
            Show Summary Only</label
          >
        </div>
      </div>
    </form>

    <div class="row row-cols-2 mt-3">
      @if(scheduleData){
      <div class="col">
        <button
          class="btn btn-danger btn-lg w-100"
          type="button"
          (click)="deleteSchdeule()"
        >
          Delete
        </button>
      </div>
      }@else {
      <div class="col">
        <button (click)="onCloseDialogue()" class="btn btn-dark btn-lg w-100">
          Cancel
        </button>
      </div>
      }

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          (click)="currentStep = 2"
          [disabled]="!schedulerForm.valid"
        >
          Send To
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentStep === 2">
    <div class="mb-3">
      <gtapp-auto-complete
        [data]="contactLookupList"
        placeHolder="Send Reports To"
        searchKeyword="user_full_name"
        [itemTemplate]="userTemplate"
        [addInitialKey]="true"
        (inputChanged)="contactLookup($event)"
        (selected)="addUserToSelected($event)"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #userTemplate let-user>
      @if (user?.key === 0) {
      <div>Add New Contact</div>
      } @else {
      <div>
        {{ user?.user_full_name }}
        <div
          *ngIf="user?.location_contact_id"
          text="Onsite Contact"
          class="badge"
        ></div>
        <div>
          <small>
            {{ user?.user_full_name ? user.user_email : "" }}
            {{
              user?.user_mobile_number ? " , " + user?.user_mobile_number : ""
            }}
          </small>
        </div>
      </div>
      }
    </ng-template>
    <table class="table my-2">
      <tbody>
        <tr
          *ngFor="let user of clientContactList"
          class="cursor-pointer"
          (click)="addRemoveUser(user)"
        >
          <td>
            <div class="d-flex">
              <div>
                <div class="form-floating">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="user?.selected"
                  />
                </div>
              </div>
              <div class="flex-grow-1 ms-2">
                <div>
                  {{ user?.user_full_name }}
                </div>
                <div class="small">
                  {{ user?.user_full_name ? user.user_email : "" }}
                  {{
                    user?.user_mobile_number
                      ? " , " + user?.user_mobile_number
                      : ""
                  }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button (click)="currentStep = 1" class="btn btn-dark btn-lg w-100">
          Back
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          [disabled]="!schedulerForm.valid || !selectedUsers?.length"
          type="button"
          (click)="scheduleData ? updateSchedule() : saveSchedule()"
        >
          {{ scheduleData ? "Update Schedule" : "Create Schedule" }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
