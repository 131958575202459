<div class="card dialog-box">
  <div style="max-height: 100%">
    <div class="card-header">
      {{ editSite ? "Edit Site Details" : "Add New Site" }}
      <span class="float-end" (click)="onCloseDialogue()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <!-- Forms for Site Details -->
      <form *ngIf="!editSite" [formGroup]="siteForm">
        <!-- Stepper 1 -->

        <div>
          <div class="row row-cols-1">
            <gtapp-input-field
              dtValue="Site Name"
              inputId="siteInputField"
              [maxLength]="256"
              [inputStringValue]="siteForm.value.company_name"
              (blurEmit)="siteForm.controls['company_name'].setValue($event)"
            >
            </gtapp-input-field>

            <gtapp-address-lookup
              [addressForm]="siteForm"
              placeholder="Address*"
            >
            </gtapp-address-lookup>
            <ng-container *ngIf="addSiteFormData?.client.data?.id">
              <dl class="p-2">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <dt>Client</dt>
                    <dd>
                      {{ addSiteFormData?.client.value }}
                    </dd>
                  </div>
                  <div>
                    <span (click)="editField('client')">
                      <i
                        class="fa-solid fa-pen-to-square md-font-size text-secondary"
                      ></i
                    ></span>
                  </div>
                </div>
              </dl>
            </ng-container>
            <div class="mb-2" *ngIf="!addSiteFormData?.client.data?.id">
              <gtapp-auto-complete
                [data]="clientDataList"
                placeHolder="Client*"
                searchKeyword="company_name"
                [initialValue]="addSiteFormData.client.tempValue"
                [itemTemplate]="ClientTemplate"
                [addInitialKey]="true"
                (inputChanged)="onClientSearch($event)"
                (selected)="onClientSelect($event)"
                [inputId]="'clientId'"
                [enableCloseOption]="true"
                (fieldCleared)="editField('client')"
              >
              </gtapp-auto-complete>

              <ng-template #ClientTemplate let-item>
                @if (item?.key === 0) {
                <div>Add New Client</div>
                } @else {
                <div>
                  <div>{{ item?.company_name }}</div>
                  <div class="small-font text-secondary">
                    {{ item?.address?.full_address }}
                  </div>
                </div>
                }
              </ng-template>
            </div>

            <div class="small ms-2">
              <em>Fields marked with * cannot be empty</em>
            </div>

            <div class="row row-cols-2 mt-2">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-dark btn-lg w-100"
                  (click)="onCloseDialogue()"
                >
                  Cancel
                </button>
              </div>

              <div class="col">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg w-100"
                  id="submitbtn1"
                  (click)="updateData()"
                  [disabled]="!siteForm.valid"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form *ngIf="editSite" [formGroup]="siteForm">
        <ng-container *ngIf="editSiteName">
          <div class="form-floating">
            <input
              class="form-control"
              formControlName="company_name"
              type="text"
              placeholder="Site Name"
              id="siteName"
            />
            <label for="siteName">Site Name</label>
          </div>
        </ng-container>
        <ng-container *ngIf="!editSiteName">
          <gtapp-address-lookup
            [addressForm]="siteForm"
            [editSelectedAddress]="true"
          >
          </gtapp-address-lookup>
        </ng-container>
        <div class="row row-cols-2 mt-2">
          <div class="col">
            <button
              class="btn btn-dark btn-lg btn-block w-100"
              (click)="onCloseDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              id="submitbtn"
              (click)="updateData()"
              class="btn btn-primary btn-lg w-100"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
