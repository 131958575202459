import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { currentTheme } from '../../../global.variable';
import { AppService } from '../../app.service';
import { ProfileService } from '../../pages/profile/profile.service';
import { DataCheckService } from '../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'gtapp-external-app',
  templateUrl: './external-app.component.html',
  styleUrl: './external-app.component.scss',
})
export class ExternalAppComponent implements OnInit {
  currentTheme: string = currentTheme;
  showErrorMsg: any;
  showSuccessMsg: any;

  refreshToken: any;
  showDetail: any;
  userData: any;

  rememberLogin: number | undefined;
  isSuperUser: any;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private authService: AuthService,
    private dataCheckService: DataCheckService
  ) {}

  appLogIn(response: any) {
    this.appService.setUserData(response);
    this.refreshToken = response['token'];
    if (this.userData?.access) {
      this.authService.checkDevice().then((response) => {
        this.spinnerService.hide();
      });
    }

    if (response?.link_from === 'user_invitation') {
      this.redirectDashBoard();
    }

    if (response?.link_from === 'registration') {
      this.redirectDashBoard();
    }
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    var key = this.route.snapshot.paramMap.get('key');
    if (key) {
      this.appService
        .gtExternalLink('register_login/check_gt_link', {}, key, false)
        .then((response: any) => {
          if (response['status'] === 'success') {
            if (!response?.link_from) this.redirectDashBoard();
            else {
              this.showDetail = response?.link_from;
            }
            if (response?.link_from === 'unblock_account') {
              localStorage.setItem('unblock_account', response?.message);
              this.router.navigate([`/login`]);
            }
            this.showSuccessMsg =
              response?.link_from === 'stop_mail_alerts'
                ? response?.message
                : '';
            this.userData = response;
            if (this.userData?.profile?.id) {
              this.appLogIn(response);
            }

            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.showErrorMsg = response['message'];
            this.showSuccessMsg = undefined;
          }
          this.spinnerService.hide();
        });
    }
  }

  redirectDashBoard() {
    if (this.isSuperUser === true) {
      this.router.navigate(['gtadmin/dashboard']);
    } else {
      this.router.navigate(['/']);
    }
  }

  onCloseDialogue() {
    this.redirectDashBoard();
  }
}
