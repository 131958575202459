import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import {
  getFormattedDate,
  getFormattedDateTime,
  getFormattedTime,
  getWelfareCheckInterval,
  globalPatrolRouteStatusDict,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ImagePreviewComponent } from '../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { QrCodeComponent } from '../../../../shared/components/qr-code/qr-code.component';
import { SchedulerComponent } from '../../../../shared/components/scheduler/scheduler.component';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { gTDB } from '../../../../shared/services/db';
import { DeviceInfoService } from '../../../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { CheckpointService } from '../../checkpoints/checkpoint.service';
import { PagesService } from '../../pages.service';
import { RosterScheduleService } from '../../roster-schedule/roster-schedule.service';
import { AddEditUserComponent } from '../../users/add-edit-user/add-edit-user.component';
import { JobsService } from '../jobs.service';
@Component({
  selector: 'gtapp-patrol-detail',
  templateUrl: './patrol-detail.component.html',
  styleUrl: './patrol-detail.component.scss',
})
export class PatrolDetailComponent implements OnInit {
  // @ViewChild(CustomPipe) customPipe: CustomPipe;
  routeId: any;
  detailData: any;

  // change schedule component
  validEndDate: any;

  dialogRef: any;
  hasQrCheckpoint: boolean = false;
  patrolRouteCpRows: number = 10;
  patrolRouteCpPrevious: number = 0;
  patrolRouteCpPageNum: number = 1;
  patrolRouteCpTotalPages: number = 0;
  patrolRouteCPList: any = [];
  patrolRouteCpTotalCount: number = 0;
  patrolRouteCpCount: number = 0;
  checkpointSearchList: any = [];
  selectedCheckpoints: any = [];
  patrolCpFilterValue: any;

  assigneeList: any = [];
  selectedAssignees: any = [];
  assigneeSearchList: any = [];
  patrolRouteAssigneeRows: number = 25;
  patrolRouteAssigneePrevious: number = 0;
  patrolRouteAssigneePageNum: number = 1;
  patrolRouteAssigneeTotalPages: number = 0;
  patrolRouteAssigneeTotalCount: number = 0;
  indexedDbListData: any = [];
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  canScheduleBeUpdated: boolean = false;
  showWelfareItem: boolean = true;
  hasScheduleStarted: boolean = false;
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });

  scheduleDuration: any;
  intervalData: any = [];
  welfareReminderDict: any = {};
  newPatrolName: any;
  selectedInterval: any;
  patrolRouteStatusKey: number = 0;
  patrolRouteStatus: any = globalPatrolRouteStatusDict;

  scheduleCheck: boolean = true;
  updateTask: any;
  patrolUpdateLog: any = [];
  patrolLogUpdates: any = [];
  patrolLogTableDetail = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },

    {
      header: 'Update',
      name: 'action',
      sortKey: 'action',
      searchKey: 'action',
    },
  ];
  subscriberUserId: any;
  offlineMode: boolean = false;
  newComment: any;
  previousComment: any;

  filterPatrolData: any = { checkpoint: [], company: [], site: [] };
  selectedEvent: any;
  scanComplete: boolean = false;
  scannedCheckpointCount: number = 0;
  messageChannel = new MessageChannel();
  apidataUpdation: boolean = false;

  missedCheckpoints: any = [];
  scannedCheckpoints: any = {};
  newInfromation: any;
  changeSortOrder: boolean = false;
  largeView = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  endDateTime: any;
  patrolTime = { startTime: new Date(), endTime: new Date() };
  showMap: any;

  updateBtnPressed: boolean = false;
  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private pageService: PagesService,
    private jobService: JobsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: ModelDialogueService,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private rsService: RosterScheduleService,
    private ngZone: NgZone,
    private swUpdate: SwUpdate,
    private sanitizer: DomSanitizer,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private deviceInfoService: DeviceInfoService,
    private cdr: ChangeDetectorRef
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['rKey']) {
          this.routeId = params?.['rKey'];
          this.spinnerService.show();
          this.getPatrolRouteDetail();
        }
      },
    });
  }

  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.offlineMode = !value?.appStatus;
        if (value?.appStatus) {
          // case where user is not doing update, which implies we can update the ui from offline to online or vice versa
          let offlineReloadCheck = this.offlineMode;

          if (this.offlineMode == false && offlineReloadCheck) {
            this.pageService.miscSubjectParam.next({ syncData: true });
          }
        }
      }
    });
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    this.subscriberUserId =
      this.appService.getUserData()?.subscriber?.subscriber_user_id;
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_PATROL_DETAIL_PORT',
      },
      [this.messageChannel.port2]
    );
    // // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.data) {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.detailData = event?.data?.data;
            this.formatHistory(event?.data);
            this.formatExistingData();

            setTimeout(() => {
              this.formatCachedList();
            }, 1000);
            this.apidataUpdation = false;
          }, 100);
        });
      }
      if (event?.data?.history) {
        this.formatHistory(event?.data);

        setTimeout(() => {
          this.formatCachedList();
        }, 1000);
      }
      if (event?.data?.message) {
        if (event?.data?.status == 'success') {
          this.toasterService.setMessage({
            successMessage: this.offlineMode
              ? 'Action Queued'
              : event?.data?.message,
            errorMessage: '',
          });
        } else {
          this.toasterService.setMessage({
            errorMessage: event?.data?.message,
            successMessage: '',
          });
        }
      }
    };
  }
  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  getTimeDifference() {
    let startTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();
    let endTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();

    if (this.detailData?.roster_scheduler?.repeat_type == 'once') {
      startTime = new Date(
        this.detailData?.roster_scheduler?.start_day +
          ' ' +
          this.detailData?.roster_scheduler?.start_time
      );
      endTime = new Date(
        this.detailData?.roster_scheduler?.end_day +
          ' ' +
          this.detailData?.roster_scheduler?.end_time
      );
    } else {
      if (
        this.detailData?.roster_scheduler?.start_time >
        this.detailData?.roster_scheduler?.end_time
      ) {
        endTime.setDate(startTime.getDate() + 1);
      }
      this.setTime(startTime, this.detailData?.roster_scheduler?.start_time);
      this.setTime(endTime, this.detailData?.roster_scheduler?.end_time);
    }
    this.patrolTime = { startTime: startTime, endTime: endTime };
    let startTimeCopy = new Date(startTime.getTime());
    let endTimeCopy = new Date(endTime.getTime());
    this.scheduleDuration = this.getTimeDiff(startTimeCopy, endTimeCopy);
    this.scheduleCheckFn(startTimeCopy, endTimeCopy);
  }
  onLoadMore() {
    this.patrolRouteCpPrevious =
      this.patrolRouteCpPrevious + this.patrolRouteCpRows;

    this.getPatrolRouteCheckpoints();
  }
  onLoadMoreAssignees() {
    this.patrolRouteAssigneePrevious =
      this.patrolRouteAssigneePrevious + this.patrolRouteAssigneeRows;

    this.getPatrolRouteAssignees();
  }
  pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  getTimeDiff(startTime: any, endTime: any) {
    const maxStartTime = Math.max(startTime?.getTime(), new Date().getTime());

    const diffValue = endTime?.getTime() - maxStartTime;

    var minuteDifference = diffValue / (1000 * 60);

    [this.intervalData, this.welfareReminderDict] =
      getWelfareCheckInterval(minuteDifference);

    let diffInTime = endTime?.getTime() - startTime?.getTime();

    if (!diffInTime) return '00:00';

    let seconds = Math.floor(diffInTime / 1000);

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);

    return `${this.pad(hours)}:${this.pad(minutes)}`;
  }
  setTime(date: Date, time: string) {
    const [hours, minutes] = time?.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
  }
  sheduleIfChecks() {
    const now = new Date();

    const startTime = new Date(
      this.detailData.roster_scheduler.start_day +
        ' ' +
        this.detailData.roster_scheduler.start_time
    );
    const endTime = new Date(
      this.detailData.roster_scheduler.end_day +
        ' ' +
        this.detailData.roster_scheduler.end_time
    );

    const canUpdateSchedule =
      endTime.getTime() > now.getTime() &&
      (!this.detailData?.schedule_event_date ||
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) >= this.formatDateWithoutTime(now));

    if (!(this.isAdmin || this.isDispatchUser)) {
      this.canScheduleBeUpdated = false;
      this.showWelfareItem = canUpdateSchedule;

      return;
    }

    this.canScheduleBeUpdated = canUpdateSchedule;

    this.showWelfareItem = this.canScheduleBeUpdated;

    this.hasScheduleStarted = startTime.getTime() < new Date().getTime();
  }
  scheduleCheckFn(startDatetime: any, endDateTime: any) {
    this.scheduleCheck =
      !this.detailData?.roster_scheduler?.repeat_type || this.isAdmin;
    const today = this.formatDateWithoutTime(new Date());
    if (!this.scheduleCheck) {
      this.ngZone.run(() => {
        this.scheduleCheck =
          this.formatDateWithoutTime(startDatetime) <= today &&
          this.formatDateWithoutTime(endDateTime) >= today;
      });
    }
  }

  getPatrolRouteDetail() {
    this.rsService
      .getPatrolRoute({
        patrol_route_id: this.routeId,
      })
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.detailData = response?.data;
          this.pageService.miscSubjectParam.next({
            isOnEvent: true,
          });
          this.formatHistory(response);

          this.formatExistingData();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }

        this.spinnerService.hide();
      });
  }
  openUserDialogBox(user: any) {
    const dialogRefUser = this.dialogService.open(AddEditUserComponent, {
      data: {
        editUser: true,
        detailData: user,
      },
    });
    dialogRefUser.afterClosed().subscribe((value: any) => {});
  }
  formatExistingData() {
    //check whether offline and localhost, if so change apidataUpdation = false
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.apidataUpdation = false;
    }
    this.selectedEvent = null;
    this.spinnerService.hide();
    this.dialogRef?.close();

    this.selectedCheckpoints = JSON.parse(
      JSON.stringify(this.detailData?.check_points)
    );
    this.selectedAssignees = JSON.parse(
      JSON.stringify(this.detailData?.subscriber_users)
    );
    this.filterPatrolData = { checkpoint: [], company: [], site: [] };
    this.hasQrCheckpoint = false;
    this.detailData?.check_points?.forEach((checkpoint: any) => {
      if (!this.filterPatrolData.checkpoint.includes(checkpoint?.id)) {
        this.filterPatrolData.checkpoint.push(checkpoint?.id);
      }
      if (checkpoint?.site_id) {
        if (!this.filterPatrolData.site.includes(checkpoint?.site_id)) {
          this.filterPatrolData.site.push(checkpoint?.site_id);
        }
      } else {
        if (!this.filterPatrolData.company.includes(checkpoint?.company_id)) {
          this.filterPatrolData.company.push(checkpoint?.company_id);
        }
      }

      if (checkpoint?.qr_code) this.hasQrCheckpoint = true;
    });
    let startDay = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : this.detailData?.roster_scheduler?.start_day
      ? new Date(this.detailData?.roster_scheduler?.start_day)
      : new Date();
    startDay.setDate(startDay.getDate() - 1);

    this.getTimeDifference();
    this.sheduleIfChecks();
    this.schedulerForm.patchValue(this.detailData?.roster_scheduler);

    this.selectedInterval = this.detailData?.interval_check
      ? this.detailData?.interval_check
      : this.intervalData[0]?.value;
    this.findScannedCheckpoints();
    this.updateBtnPressed = false;
  }

  openTemplate(template: TemplateRef<any>, updateItem: string) {
    this.patrolRouteCPList = [];
    this.assigneeList = [];
    if (updateItem === 'checkpoint') {
      this.getPatrolRouteCheckpoints();
    } else if (updateItem === 'assignee') {
      this.getPatrolRouteAssignees();
    } else if (updateItem === 'name') {
      this.newPatrolName = this.detailData?.name;
    }
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openAssigneeDetail(assignee: any) {
    if (assignee.id) {
      this.dialogService.open(AddEditUserComponent, {
        data: {
          editUser: true,
          detailData: assignee,
        },
      });
    }
  }

  getPatrolRouteCheckpoints(event?: any) {
    let params: any = {};
    if (this.patrolRouteCpRows) {
      params['rows'] = this.patrolRouteCpRows;
    }
    if (this.patrolRouteCpPrevious) {
      params['previous'] = this.patrolRouteCpPrevious;
    }

    if (event?.target?.value) {
      params['name'] = event.target.value;
    }
    this.spinnerService.show();
    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.patrolRouteCPList.push(...response?.data);
        this.patrolRouteCPList = this.patrolRouteCPList?.filter(
          (x: any, index: any, y: any) =>
            y?.findIndex((t: any) => t?.id === x?.id) === index
        );
        this.patrolRouteCpTotalCount = response['total_size'];
        this.patrolRouteCpCount = response['cp_count'];
        this.patrolRouteCpTotalPages = Math.ceil(
          this.patrolRouteCpTotalCount / this.patrolRouteCpRows
        );
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedCP(selectedIndex: number) {
    this.showMap = false;
    this.selectedCheckpoints = this.selectedCheckpoints?.filter(
      (checkpoint: any, index: number) => index !== selectedIndex
    );
  }
  addSelectedCP(addCheckpoint: any) {
    this.showMap = false;
    this.selectedCheckpoints.push(addCheckpoint);
  }
  addAllCheckpoints() {
    this.patrolRouteCPList?.forEach((checkpoint: any) =>
      this.addSelectedCP(checkpoint)
    );
  }

  searchCheckPoints(event?: any) {
    this.patrolCpFilterValue = event.target.value;
    if (event.target.value?.length > 2) {
      let params: any = { name: event.target.value };

      this.checkpointService
        .getCheckpoints(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.patrolRouteCPList = response?.data;
            this.patrolRouteCpTotalCount = response['total_size'];
            this.patrolRouteCpTotalPages = Math.ceil(
              this.patrolRouteCpTotalCount / this.patrolRouteCpRows
            );
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    } else if (!event.target.value?.length) {
      this.patrolRouteCPList = [];
      this.patrolRouteCpRows = 10;
      this.patrolRouteCpPrevious = 0;
      this.patrolRouteCpPageNum = 1;
      this.patrolRouteCpTotalPages = 0;
      this.getPatrolRouteCheckpoints();
    }
  }
  getPatrolRouteAssignees() {
    let params: any = {};
    if (this.patrolRouteAssigneeRows) {
      params['rows'] = this.patrolRouteAssigneeRows;
    }
    if (this.patrolRouteAssigneePrevious) {
      params['previous'] = this.patrolRouteAssigneePrevious;
    }

    this.spinnerService.show();
    this.jobService.getAssignees(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList.push(...response?.data);

        this.patrolRouteAssigneeTotalCount = response?.total_count;
        this.patrolRouteAssigneeTotalPages = Math.ceil(
          this.patrolRouteAssigneeTotalCount / this.patrolRouteAssigneeRows
        );

        this.makeAssigneeSelectionChanges();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedAssignee(deleteAssignee: any) {
    this.selectedAssignees = this.selectedAssignees?.filter(
      (assignee: any) => assignee?.id !== deleteAssignee?.id
    );
    this.makeAssigneeSelectionChanges();
  }
  addSelectedAssignee(addAssignee: any) {
    if (
      this.selectedAssignees?.some(
        (assignee: any) => assignee?.id === addAssignee?.id
      )
    ) {
      this.toasterService.setMessage({
        errorMessage: 'Assignee Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedAssignees.push(addAssignee);
    }

    this.makeAssigneeSelectionChanges();
  }
  addRemovessignee(assignee: any) {
    assignee.selected = !assignee?.selected;
    if (assignee.selected) {
      this.addSelectedAssignee(assignee);
    } else {
      this.removeSelectedAssignee(assignee);
    }
  }
  makeAssigneeSelectionChanges() {
    this.assigneeList.forEach((assignee: any) => {
      assignee.selected = this.selectedAssignees?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
    });
    this.assigneeList.sort((a: any, b: any) => {
      if (a?.selected === b?.selected) {
        return 0;
      }
      return a?.selected ? -1 : 1;
    });
  }

  searchAssignee(event?: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.assigneeSearchList?.length)
    ) {
      this.jobService
        .getAssignees({ full_name: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.assigneeSearchList = res?.data;
          }
        });
    }
  }
  checkpointUpdateDisabling(): boolean {
    if (!this.selectedCheckpoints?.length) {
      return true;
    }
    if (
      this.selectedCheckpoints?.length ===
        this.detailData?.check_points?.length &&
      this.selectedCheckpoints?.every(
        (obj1: any, index: number) =>
          this.detailData?.check_points?.[index]?.id === obj1?.id
      )
    ) {
      return true;
    }

    return false;
  }
  assigneeUpdateDisabling() {
    if (!this.selectedAssignees?.length) {
      return true;
    }

    if (
      this.selectedAssignees?.length ===
        this.detailData?.subscriber_users?.length &&
      this.selectedAssignees?.every((obj1: any) =>
        this.detailData?.subscriber_users?.some(
          (obj2: any) => obj1?.id === obj2?.id
        )
      )
    ) {
      return true;
    }

    return false;
  }
  updatePatrolRoute(updateType: string) {
    this.spinnerService.show();
    this.updateBtnPressed = true;
    let updateData: any = { patrol_route_id: this.routeId };
    let reqParams: any = {};

    switch (updateType) {
      case 'checkpoint':
        updateData.checkpoints = [
          ...new Set(
            this.selectedCheckpoints?.map((checkpoint: any) => ({
              id: checkpoint?.id,
            }))
          ),
        ];
        reqParams.update_checkpoints = 1;
        break;

      case 'assignee':
        updateData.assignees = [
          ...new Set(
            this.selectedAssignees?.map((assignee: any) => assignee?.id)
          ),
        ];
        reqParams.update_assignees = 1;
        break;

      case 'name':
        updateData.name = this.newPatrolName;
        reqParams.update_name = 1;
        break;
      case 'welfare_interval':
        updateData.interval_check = this.selectedInterval;
        reqParams.update_interval_check = 1;
        break;
      case 'patrol_brief':
        updateData.patrol_brief = this.newInfromation;
        reqParams.update_patrol_brief = 1;
        break;
      case 'generate_missed_report':
        reqParams.generate_missed_report = 1;
    }

    this.rsService
      .managePatrolRoute(updateData, reqParams)
      .then((response: any) => {
        if (response?.status === 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);
          this.formatExistingData();
          this.toasterService.setMessage({
            successMessage: response?.message,
            errorMessage: '',
          });
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.updateBtnPressed = false;
        }

        this.spinnerService.hide();
      });
  }
  shiftClick(elementID: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementID);
      element?.click();
      element?.focus();
    }, 100);
  }

  deletePatrolRoute() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: 'This will remove all the route and schedule information!',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.rsService
          .managePatrolRoute(
            {
              patrol_route_id: this.routeId,
            },
            { delete_schedule: 1 }
          )
          .then((response: any) => {
            // this.formatData(response);
            if (response?.status === 'success') {
              this.router.navigate(['/roster']);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response?.message,
              });
            }

            this.spinnerService.hide();
          });
      }
    });
  }
  startEndTimeComparison() {
    let startTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.start_time
    );
    let endTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.end_time
    );

    return startTime.getTime() > endTime.getTime();
  }
  openScheduleTemplate(template: TemplateRef<any>) {
    if (this.isAdmin === true || this.isDispatchUser === true) {
      this.schedulerForm.patchValue(this.detailData?.roster_scheduler);
      if (
        (this.detailData?.roster_scheduler?.repeat_type === 'once' &&
          this.hasScheduleStarted) ||
        (this.hasScheduleStarted &&
          this.formatDateWithoutTime(
            new Date(this.detailData?.schedule_event_date)
          ) == this.formatDateWithoutTime(new Date()))
      ) {
        this.validEndDate = getFormattedDateTime();
        let endDate = new Date(
          this.schedulerForm.value.end_day +
            ' ' +
            this.schedulerForm.value.end_time
        );
        if (this.detailData?.roster_scheduler?.repeat_type !== 'once') {
          endDate = this.detailData?.schedule_event_date
            ? new Date(
                this.detailData?.schedule_event_date +
                  ' ' +
                  this.schedulerForm.value.end_time
              )
            : new Date();
          if (this.startEndTimeComparison()) {
            endDate.setDate(endDate.getDate() + 1);
          }
          this.schedulerForm.controls['start_day'].setValue(
            this.detailData?.schedule_event_date
              ? new Date(this.detailData?.schedule_event_date)
              : new Date()
          );

          this.schedulerForm.controls['end_day'].setValue(endDate);
        }

        this.endDateTime = getFormattedDateTime(endDate);
        this.schedulerForm.controls['repeat_type'].setValue('once');
        this.dialogRef = this.dialogService.open(
          template,
          {},
          this.viewContainerRef
        );
      } else {
        this.dialogRef = this.dialogService.open(SchedulerComponent, {
          data: {
            schedulerForm: this.schedulerForm,
            editSchedule: true,
            repeatAllowed:
              this.detailData?.roster_scheduler?.repeat_type != 'once',
          },
        });
        this.dialogRef.afterClosed().subscribe((value: any) => {
          if (value) {
            const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
              data: {
                title: 'Are you sure?',
              },
            });
            dialogRef.afterClosed().subscribe((dialogResponse) => {
              if (dialogResponse === true) {
                this.updateSchedule();
              }
            });

            this.schedulerForm.patchValue(value);
          }
        });
      }
    }
  }
  changeEndDateTime(event: any) {
    this.schedulerForm.controls['end_day'].setValue(
      getFormattedDate(event.target.value)
    );
    this.schedulerForm.controls['end_time'].setValue(
      getFormattedTime(event.target.value)
    );
  }
  formatScheduleForm(form: any) {
    form.value.start_day =
      form.value.start_day == ''
        ? ''
        : formatDate(form.value.start_day, 'yyyy-MM-dd', 'en');
    form.value.end_day =
      form.value.end_day == null
        ? null
        : formatDate(form.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();
    return {
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...form.value,
    };
  }

  getUpdateScheduleParams() {
    let params: any = {};

    let paramKey: any = this.hasScheduleStarted
      ? 'started_schedule'
      : 'future_schedule';
    if (
      this.formatDateWithoutTime(
        new Date(this.detailData?.schedule_event_date)
      ) == this.formatDateWithoutTime(new Date()) &&
      this.hasScheduleStarted &&
      this.detailData?.roster_scheduler?.repeat_type !== 'once'
    ) {
      paramKey = 'started_job';
    }
    params[paramKey] = 1;
    return params;
  }
  updateSchedule() {
    this.spinnerService.show();

    let data: any = {
      patrol_route_id: this.detailData?.id,
      time_offset: new Date().getTimezoneOffset(),
      ...((this.detailData?.roster_scheduler?.repeat_type === 'once' &&
        this.hasScheduleStarted) ||
      (this.hasScheduleStarted &&
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) == this.formatDateWithoutTime(new Date()))
        ? this.formatScheduleForm(this.schedulerForm)
        : this.schedulerForm.value),
    };

    this.rsService
      .updateRosterSchedule(
        this.detailData?.roster_scheduler?.id,
        data,
        this.getUpdateScheduleParams()
      )
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          if (
            this.detailData?.roster_scheduler?.repeat_type === 'once' &&
            this.hasScheduleStarted
          )
            this.getPatrolRouteDetail();
          else {
            this.router.navigate(['/roster']);
          }
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }

        this.spinnerService.hide();
      });
  }
  openIntervalCheckTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
  }
  updateInterval(interval: Number) {
    this.selectedInterval = interval;
    this.updatePatrolRoute('welfare_interval');
  }
  async closeDetailedHome() {
    if (
      [
        'scanCheckPoint',
        'addSiteComment',
        'addSitePhoto',
        'updateJob',
      ].includes(this.updateTask)
    ) {
      this.updateTask = null;
    }
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.getPatrolUpdates();
    }
    await this.formatCachedList();
  }
  async formatCachedList() {
    const loggedInUsersUpdates = this.patrolUpdateLog?.filter(
      (item: any) =>
        item?.subs_user_id == this.subscriberUserId &&
        [0, 1, 2, 6, 7].includes(item?.event_action)
    );

    this.indexedDbListData = await this.getAllIndexedDbItems();

    let remainingDataList: any = this.indexedDbListData?.filter(
      (cachedItem: any) =>
        cachedItem?.patrol_route_id === this.detailData?.id &&
        !loggedInUsersUpdates?.some(
          (item: any) =>
            item?.index_db_id == cachedItem?.indexDBId &&
            new Date(cachedItem?.updated_at * 1000).getTime() ===
              new Date(item?.recorded_at).getTime()
        )
    );

    remainingDataList?.forEach((item: any) => {
      let data = this.formatCachedData(item);
      if (
        !this.patrolUpdateLog?.some(
          (item: any) =>
            item?.cachedData && item?.index_db_id == data?.index_db_id
        )
      ) {
        this.patrolUpdateLog.push(data);
      }
    });
    this.patrolUpdateLog = this.patrolUpdateLog?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    this.patrolLogUpdates = this.patrolUpdateLog?.filter(
      (history: any) => !history.source
    );
    let updatedPatrolEvents: any = this.indexedDbListData
      ?.filter(
        (cachedItem: any) =>
          cachedItem?.patrol_route_id === this.detailData?.id &&
          cachedItem?.apiCallSuccess &&
          loggedInUsersUpdates?.some(
            (item: any) =>
              item?.index_db_id == cachedItem?.indexDBId &&
              new Date(cachedItem?.updated_at * 1000).getTime() ===
                new Date(item?.recorded_at).getTime()
          )
      )
      ?.map((item: any) => item?.indexDBId);

    this.deleteCachedData(updatedPatrolEvents);
  }
  formatCachedData(item: any) {
    let eventDict: any = {};
    if (item?.pathName === '/api/patrol/add_update/') {
      if (item?.image_source === 'live') {
        eventDict = { event_action: 2, action: 'Uploaded Image' };
      } else if (item?.image_source === 'device') {
        eventDict = { event_action: 7, action: 'File Uploaded' };
      } else {
        eventDict = { event_action: 1, action: 'Added Comment' };
      }
    } else if (item?.pathName === '/api/patrol/scan_checkpoint/') {
      eventDict = item?.qr_text
        ? { event_action: 6, action: 'Scanned Qr' }
        : { event_action: 0, action: 'Scanned Checkpoint' };
    }
    return {
      cachedData: true,
      detail: item?.comment || null,
      gps: item?.device_info?.gps,
      updated_at: item?.updated_at * 1000,
      recorded_at: item?.updated_at * 1000,
      index_db_id: item?.indexDBId,
      file: item?.file || null,
      subs_user_id: this.subscriberUserId,
      ...eventDict,
      device_details: {
        Ip: item?.device_info?.gps?.ip,
        Browser: `${item?.device_info?.browser} - ${item?.device_info?.browser_version}`,
        Device: `${item?.device_info?.device} - ${item?.device_info?.deviceType} `,
      },
    };
  }

  editComment(commentData: any, requestRef: TemplateRef<any>) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: commentData,
      },
      this.viewContainerRef
    );
  }
  addComment(data: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body = {
        company_id: data?.company_id,
        comment: this.newComment,
        id: data?.id,
        edit_comment: 1,
        patrol_route_id: this.detailData?.id,
      };
      this.checkpointService.updateClient(body).then((response: any) => {
        this.newComment = '';

        this.formatHistory(response);

        this.formatExistingData();
      });
    }
  }
  onOpenImage(event: any, data: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;

    this.dialogRef = this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: srcAttr.nodeValue, timeStamp: data.updated_at },
    });
  }
  getPatrolUpdates() {
    this.rsService
      .managePatrolRoute(
        {
          patrol_route_id: this.routeId,
        },
        { history: 1 }
      )
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.formatHistory(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }

        this.spinnerService.hide();
      });
  }
  async formatHistory(response: any) {
    this.patrolLogUpdates = [];
    this.patrolUpdateLog = JSON.parse(JSON.stringify(response?.history?.data));
    await this.formatCachedList();
    this.missedCheckpoints = response?.history?.missed_checkpoints;

    this.findScannedCheckpoints();
    this.patrolRouteStatusKey = this.scanComplete
      ? 1
      : response?.history?.patrol_route_status;
  }

  findScannedCheckpoints() {
    let alreadyScannedCheckpoint: any = this.patrolLogUpdates
      ?.filter(
        (update: any) =>
          [0, 6].includes(update?.event_action) &&
          !update?.cachedData &&
          this.patrolTime.startTime.getTime() <=
            new Date(update?.updated_at).getTime() &&
          new Date(update?.updated_at).getTime() <=
            this.patrolTime.endTime.getTime()
      )
      ?.map((update: any) => update?.event_id);

    this.scannedCheckpoints = {};

    alreadyScannedCheckpoint?.forEach((item: any) => {
      if (this.scannedCheckpoints[item]) {
        this.scannedCheckpoints[item]++;
      } else {
        this.scannedCheckpoints[item] = 1;
      }
    });
    this.detailData?.check_points?.forEach((checkpoint: any) => {
      if (
        this.scannedCheckpoints?.[checkpoint?.id] &&
        this.scannedCheckpoints?.[checkpoint?.id] > 0
      ) {
        checkpoint.isScanned = true;
        this.scannedCheckpoints[checkpoint?.id] =
          this.scannedCheckpoints[checkpoint?.id] - 1;
      }
    });

    this.scanComplete = this.detailData?.check_points?.every(
      (checkpoint: any) => checkpoint?.isScanned
    );
    this.scannedCheckpointCount = this.detailData?.check_points?.filter(
      (checkpoint: any) => checkpoint?.isScanned
    )?.length;
    this.detailData?.check_points?.sort((a: any, b: any) => {
      if (a?.isScanned === b?.isScanned) {
        return -1;
      }
      return a?.isScanned ? 1 : -1;
    });
  }

  onTableRowClick(eventData: any) {
    this.selectedEvent = eventData;
  }
  getImgSrc(file: File): SafeUrl {
    let url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onOpenFile(file: any) {
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogRef = this.dialogService.open(ImagePreviewComponent, {
        data: { imageSrc: file?.file_url, timeStamp: file.updated_at },
      });
    }
  }

  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteFile(file: any) {
    let dialogMsg = `Permanently delete file`;
    this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete File`,
        message: dialogMsg,
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();

        this.deletePatrolFile(file);
      }
    });
  }

  deletePatrolFile(file: any) {
    this.spinnerService.show();
    this.rsService
      .managePatrolRoute(
        {
          patrol_route_id: this.routeId,
          file_id: file?.id,
        },
        { remove_patrol_brief: 1 }
      )
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);
          this.formatExistingData();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }

        this.spinnerService.hide();
      });
  }
  handleFileInput(event: any) {
    this.uploadPatrolBrief(event?.target?.files.item(0));
  }
  uploadPatrolBrief(file: any) {
    let params: any = {};
    params['add_patrol_brief'] = 1;
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('patrol_route_id', this.routeId);
    this.spinnerService.show();
    this.appService
      .formDataApi(
        'roster_schedule/manage_patrol_route',
        formData,
        false,
        params
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);
          this.formatExistingData();
        } else {
          this.toasterService.setMessage({
            errorMessage: response?.message,
            successMessage: '',
          });
        }

        this.spinnerService.hide();
      });
  }
  openPatrolBriefInformationTemplate(template: TemplateRef<any>) {
    this.newInfromation = this.detailData?.patrol_brief;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }

  dropNewCheckPoint(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.selectedCheckpoints,
      event.previousIndex,
      event.currentIndex
    );
  }

  openQRCode() {
    this.dialogRef = this.dialogService.open(QrCodeComponent, {
      data: { showFrom: 'home', patrolRouteId: this.detailData?.id },
    });
    this.dialogRef?.afterClosed()?.subscribe((value: any) => {
      if (value?.history) {
        this.formatHistory(value);
      } else {
        this.getPatrolUpdates();
      }
    });
  }
  checkPointCounter(checkpointId: any) {
    return this.selectedCheckpoints?.filter(
      (item: any) => item?.id === checkpointId
    )?.length;
  }
  removeCheckpointFromSelection(checkpointId: any) {
    let lastIndex = this.selectedCheckpoints
      ?.map((item: any) => item?.id)
      .lastIndexOf(checkpointId);

    if (lastIndex != -1) {
      this.selectedCheckpoints?.splice(lastIndex, 1);
    }
  }
  async getAllIndexedDbItems() {
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const data = await gTDB.gtSavedData.toArray();
          resolve(data);
        }, 500);
      } catch (error) {
        resolve([]);
      }
    });
  }
  deleteCachedData(keys: []) {
    try {
      gTDB.gtSavedData.bulkDelete(keys);
    } catch (error) {}
  }
  generateMissedReport() {
    this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Missed Scans Report`,
        message: `Click confirm for a copy of the missed scans report for this patrol.`,
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();

        this.updatePatrolRoute('generate_missed_report');
      }
    });
  }
  clearAndFocusTextBox(elementId: string) {
    this.newComment = '';

    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 100);
  }
}
