<ng-container>
  <div class="me-lg-2 my-2 text-end">
    <button
      *ngIf="(isAdmin || isDispatchUser) && totalIncidentCount != totalRows"
      class="btn btn-sm"
      (click)="showAllIncidents()"
      [ngClass]="defaultIncidents ? 'btn-secondary' : 'btn-primary'"
    >
      {{ defaultIncidents ? "Show All" : "Reset" }}
    </button>
  </div>
  <div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
    <div class="mb-2 me-sm-2 col-lg-2">
      <button class="btn btn-primary btn-lg w-100" (click)="addIncident()">
        + Open Incident
      </button>
    </div>
    <div class="mb-2 flex-sm-grow-1" *ngIf="totalIncidentCount > 5">
      <gtapp-auto-complete
        [data]="incidentDropDownList"
        placeHolder="Search Incidents"
        searchKeyword="name"
        [initialValue]="globalFilter"
        [itemTemplate]="incidentLookupTemplate"
        (inputChanged)="onIncidentLookup($event)"
        (selected)="onSelectIncident($event)"
        (submitted)="globalFilter = $event; getIncidents()"
        [enableSearchCloseOption]="true"
        (fieldCleared)="globalFilter = null; getIncidents()"
      >
      </gtapp-auto-complete>

      <ng-template #incidentLookupTemplate let-item>
        <div>
          {{ item.incident_key }}
        </div>
        <div class="small-font">
          {{ item?.site_name }} [{{ item?.company_name }}]
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
<div *ngIf="totalIncidentCount > 0">
  @if(largeView){
  <ng-container>
    <!-- Table View for Desktop -->
    <gtapp-gt-table
      [desktopTableBackendData]="incidentList"
      [desktopTableDetails]="incidentListTableDetails"
      [tableStyle]="tableStyle"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (searchColumn)="searchColumn($event)"
      (rowClick)="onSelectIncident($event)"
      (paginationChange)="onChangePagination($event)"
    >
    </gtapp-gt-table>
  </ng-container>

  }@else{
  <ng-container>
    <!-- Card List for Mobile View -->
    <gtapp-card-list
      [mobileCardBackendData]="incidentList"
      [mobileCardDetails]="incidentCardDetails"
      (rowClick)="onSelectIncident($event)"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (rowClick)="onSelectIncident($event)"
      (paginationChange)="onChangePagination($event)"
    ></gtapp-card-list>
  </ng-container>
  }
</div>
