<div class="p-1">
  <div
    class="row row-cols-sm-1"
    [ngClass]="userProfileUrl ? 'row-cols-md-2' : ''"
  >
    <div class="col-12 col-lg-4 order-1 order-lg-3" *ngIf="userProfileUrl">
      <!-- PROFILE PICTURE -->
      <div class="row gy-2 row-cols-2 row-cols-md-1">
        <div (click)="openCamPopup()" class="profile-picture-container">
          <img
            class="mb-2"
            [ngClass]="largeView ? 'profile-picture' : 'profile-picture-mobile'"
            src="{{ userProfileUrl }}"
            alt="User-Profile-Image"
          />
        </div>
        <div
          *ngIf="userProfileUrl"
          class="col d-flex align-items-center justify-content-center"
        >
          <div class="row gy-2 row-cols-1">
            <div class="col">
              <button
                class="btn text-primary w-100 btn-md"
                (click)="openCamPopup()"
              >
                Update Profile Picture
              </button>
            </div>
            <div class="col">
              <button class="btn w-100 btn-md" (click)="removeImage()">
                Delete Profile Picture
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!userProfileUrl">
        <div class="col text-center">
          <button class="btn-primary btn-sm btn" (click)="openCamPopup()">
            + Add Profile Picture
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-8 order-2 order-lg-1">
      <div
        class="row row-col-sm-1"
        [ngClass]="userProfileUrl ? 'row-cols-lg-2' : 'row-cols-md-2'"
      >
        <div class="col-12 col-lg-8">
          <!-- USER DETAILS -->
          <div class="mb-1 row details-card">
            <div>
              <dl>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <div class="mb-2">
                      <dt>User Information</dt>
                      <dd>{{ userDetails?.full_name }}</dd>
                      <dd *ngIf="userDetails?.mobile_number">
                        <i class="fa-solid fa-phone text-secondary"></i>
                        {{ userDetails?.mobile_number }}
                      </dd>
                    </div>
                    <dt>Timezone</dt>
                    <dd>
                      {{ userDetails?.time_zone }}
                      {{ userDetails?.timeZone?.gmtOffset }}
                    </dd>
                  </div>
                  <div>
                    <span class="me-1" (click)="editProfile(profileDialog)">
                      <i
                        class="fa-solid fa-arrow-up-right-from-square md-font-size text-secondary"
                      ></i
                    ></span>
                  </div>
                </div>
              </dl>
            </div>
            <div>
              <dl>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <dt>Email</dt>
                    <dd>{{ userDetails?.email }}</dd>
                  </div>
                  <ng-container *ngIf="isTrustedDevice">
                    <div>
                      <span
                        class="me-1"
                        (click)="checkEmailEnter(updateEmailTemplateRef)"
                      >
                        <i
                          class="fa-solid fa-arrow-up-right-from-square md-font-size text-secondary"
                        ></i
                      ></span>
                    </div>
                  </ng-container>
                </div>
              </dl>
            </div>

            <dt>Notification Volume</dt>
            <dd class="d-flex justify-content-center">
              <input
                style="width: 95%"
                type="range"
                [min]="0"
                placeholder="Notification Volume"
                [max]="100"
                [(ngModel)]="muteNotification"
                (mouseup)="changeNotificationVolume()"
                (touchend)="changeNotificationVolume()"
              />
              <span class="ms-2 fw-bold">{{ muteNotification }}</span>
            </dd>
          </div>
        </div>
        <!-- BUTTONS -->
        <div class="col-12 col-lg-4">
          <div class="row gy-3">
            <div class="col-12">
              <button
                class="btn-info btn w-100 btn-lg"
                (click)="restartGuide()"
              >
                Guide
              </button>
            </div>
            <div class="col-12" *ngIf="!userProfileUrl">
              <button
                class="btn-primary btn w-100 btn-lg"
                (click)="openCamPopup()"
              >
                Upload Profile Picture
              </button>
            </div>
            <ng-container *ngIf="isTrustedDevice">
              <ng-container *ngIf="!userData?.checks?.fido_login">
                <div class="col-12">
                  <button
                    class="btn btn-success w-100 btn-lg"
                    (click)="fidoRegister()"
                  >
                    Sign In Faster with a Passkey
                  </button>
                </div>
                <div class="col-12">
                  <a
                    href="https://youtu.be/zJPNuORkvvk?t=61"
                    target="_blank"
                    rel="noopener"
                  >
                    <button class="btn btn-outline-success w-100 btn-lg">
                      Passkeys and WebAuthn
                    </button>
                  </a>
                </div>
              </ng-container>
              <div class="col-12">
                <button
                  class="btn btn-outline-primary w-100 btn-lg"
                  (click)="openPasswordForm()"
                >
                  Reset Password
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- USER LICENCES)-->
  <div class="my-2 my-md-4">
    <dl class="mb-2">
      <dt class="fw-bold d-none d-md-inline-block">
        <span class="d-flex align-items-center">
          <span>Security Licences</span>
        </span>
      </dt>
      <span>
        <button
          class="ms-2 btn-primary btn-sm btn d-none d-md-inline-block"
          (click)="addNewLicense()"
        >
          + Add Security Licence
        </button></span
      >
    </dl>
    <button
      class="d-block d-md-none btn btn-outline-primary w-100 btn-lg mb-2"
      (click)="addNewLicense()"
    >
      + Add Security Licence
    </button>

    <div>
      <gtapp-add-edit-license-details
        [licensesDataList]="userLicenses"
        [showLicensesList]="true"
        (deleteLicense)="deleteLicense($event)"
      ></gtapp-add-edit-license-details>
    </div>
  </div>
</div>
<ng-template #profileDialog let-profileRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Edit Profile
      <span class="float-end" (click)="profileRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <form [formGroup]="profileForm">
        <div class="d-flex flex-column gap-2">
          <div class="col-12 form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="first_name"
              id="first_name"
              maxlength="150"
              required
            />
            <label for="first_name">First Name</label>
          </div>
          <div class="col-12 form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="last_name"
              id="last_name"
              maxlength="150"
              required
            />
            <label for="last_name">Last Name</label>
          </div>
          <div class="col-12 form-floating">
            <input
              class="form-control"
              type="tel"
              minlength="10"
              maxlength="10"
              (input)="mobileNumberCheck()"
              formControlName="mobile_number"
              id="mobile_number"
            />
            <label for="mobile_number">Telephone</label>
            <div
              *ngIf="
                profileForm.controls['mobile_number'].invalid &&
                profileForm.controls['mobile_number'].touched
              "
              class="text-danger"
            >
              Invalid mobile number
            </div>
          </div>
          <div class="col-12 form-floating">
            <select
              class="form-control"
              formControlName="time_zone"
              id="timeZone"
              (change)="onTimeZoneChange($event)"
            >
              <option
                *ngFor="let timeZone of stateTimeZones"
                [value]="timeZone.value"
              >
                {{ timeZone.value }} {{ timeZone.gmtOffset }}
              </option>
            </select>
            <label for="timeZone">Timezone</label>
          </div>
          <div class="row row-cols-2 mt-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-dark w-100 btn-lg"
                (click)="profileRef.close()"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-primary w-100 btn-lg"
                (click)="UpdateProfileDetails(); profileRef.close()"
                [disabled]="!profileForm.valid"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #updateEmailTemplateRef let-updateEmailTemplateRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Change Email Address
      <span class="float-end" (click)="updateEmailTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <form [formGroup]="emailUpdateForm" appFormEnterAction>
      <ng-container *ngIf="emailUpdateStepper === 1">
        <div class="form-floating mb-3">
          <input
            type="email"
            class="form-control"
            [class.is-invalid]="
              emailUpdateForm.controls['new_email'].invalid &&
              emailUpdateForm.controls['new_email'].touched
            "
            formControlName="new_email"
            id="new_email"
            autocomplete="off"
            placeholder="Email Address"
            autofocus
            autocorrect="off"
            autocapitalize="off"
            (keyup.enter)="
              emailUpdateForm.valid ? sendVerificationCode() : null
            "
          />
          <label for="new_email">New Email Address</label>
          <div
            class="invalid-feedback"
            *ngIf="
              emailUpdateForm.controls['new_email'].invalid &&
              emailUpdateForm.controls['new_email'].touched
            "
          >
            Invalid email address
          </div>
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              (click)="updateEmailTemplateRef.close()"
              class="btn btn-lg btn-secondary w-100"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              (click)="sendVerificationCode()"
              class="btn btn-lg btn-primary w-100"
              type="button"
              [disabled]="emailUpdateForm.invalid"
            >
              Verify
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="emailUpdateStepper === 2">
        <div class="mb-3">
          <div class="mb-2">
            Enter the verification code sent to your new email to confirm email
            change.
          </div>
          <div class="fw-semibold">
            Failure to carry out this action means your email address will NOT
            change.
          </div>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            type="tel"
            [class.is-invalid]="
              emailUpdateForm.controls['v_code'].invalid &&
              emailUpdateForm.controls['v_code'].touched
            "
            formControlName="v_code"
            id="verCode"
            placeholder="Verification Code"
            [maxlength]="6"
            autofocus
            autocomplete="off"
            (input)="emailUpdateForm.valid ? updateEmailAddress() : null"
          />
          <label for="verCode">Verification Code</label>
          <div
            class="invalid-feedback"
            *ngIf="
              emailUpdateForm.controls['v_code'].invalid &&
              emailUpdateForm.controls['v_code'].touched
            "
          >
            Verification code is required!
          </div>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button
              (click)="updateEmailTemplateRef.close()"
              class="btn btn-lg btn-secondary w-100"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              (click)="updateEmailAddress(); updateEmailTemplateRef.close()"
              class="btn btn-lg btn-primary w-100"
              type="button"
              [disabled]="emailUpdateForm.invalid"
            >
              Confirm
            </button>
          </div>
        </div></ng-container
      >
    </form>
  </div>
</ng-template>
