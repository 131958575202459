import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import { DataCheckService } from '../../services/data-check.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-multiple-subscribers',
  templateUrl: './multiple-subscribers.component.html',
  styleUrl: './multiple-subscribers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleSubscribersComponent implements OnInit {
  userLinkedSubscribers: any;
  isClockedIn: boolean = false;
  constructor(
    protected dialogRef: DialogRef,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private profileService: ProfileService,
    private dialog: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService
  ) {
    this.getAssociatedCompanies();
  }

  ngOnInit(): void {
    this.isClockedIn = this.dataCheckService?.isGuardUserClockendIn();
  }
  getAssociatedCompanies() {
    this.spinnerService.show();
    this.profileService
      .associatedCompanies({}, { get_companies: 1 })
      .subscribe((res: any) => {
        if (res['status'] === 'success') {
          this.spinnerService.hide();
          this.userLinkedSubscribers = res['data'];
        }
      });
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  selectSubscriber(subscriber: any) {
    if (this.isClockedIn) {
      this.clockOut(subscriber);
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `Change Account`,
        },
      });
      dialogRef.afterClosed().subscribe((value) => {
        if (value === true) {
          this.changeAccount(subscriber);
        }
      });
    }
  }
  changeAccount(subscriber: any) {
    this.spinnerService.show();
    let userData = this.appService.getUserData();
    this.profileService
      .associatedCompanies({
        subscriber_id: subscriber.id,
        remember_login: userData?.token_expiry ? 1 : 0,
      })
      .subscribe((res: any) => {
        if (res['status'] === 'success') {
          const gtAppTheme: any = this.appService.getGTAppTheme();
          localStorage.clear();
          delete res['message'];
          res.checks.fido_login = userData?.checks?.fido_login ? true : false;
          this.appService.setUserData(res);
          this.appService.setGTAppTheme(gtAppTheme);
          localStorage.setItem('loginSuccess', 'true');
          window.location.reload();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }
  clockOut(subscriber: any) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Change Account',
        message: 'This will also clock you out.',
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        let params = {
          clock_out: 1,
        };
        this.spinnerService.show();
        this.profileService.guardClockedInOut(params).then((response: any) => {
          if (response?.status == 'success') {
            this.changeAccount(subscriber);
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
            this.spinnerService.hide();
          }
        });
      }
    });
  }
}
