@if(largeView){
<!-- DESKTOP VIEW -->
<div class="mt-1">
  <div class="roster-functions" id="rosterChange">
    <div class="function-child">
      <button
        (click)="previousWeek()"
        class="btn btn-lg btn-secondary w-100"
        title="previous"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        <span>&lt;</span>
      </button>
    </div>

    <div class="function-child">
      <div class="form-floating">
        <input
          type="date"
          id="dateTimePickerDesk"
          class="form-control"
          placeholder="Selected Date"
          [(ngModel)]="largeScreenDateSelected"
          (change)="changeSelectedDate($event)"
        />
        <label for="dateTimePickerDesk">Selected Date</label>
      </div>
    </div>

    <div class="function-child">
      <button
        (click)="nextWeek()"
        class="btn btn-lg btn-secondary w-100"
        title="next"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        <span>&gt;</span>
      </button>
    </div>

    <div class="function-child">
      <button
        (click)="openSearchTemplate(searchTemplateRef)"
        class="btn btn-lg btn-secondary w-100"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        Search Jobs
      </button>
    </div>

    <div class="function-child" id="btn-conflicts">
      <button
        *ngIf="conflictData?.length && (isAdmin || isDispatchUser)"
        (click)="openConflictTemplate(conflictTemplate)"
        class="btn btn-lg btn-danger w-100"
      >
        Conflicts
      </button>
    </div>

    <div class="function-child">
      <div class="slider-section">
        <div class="fw-bold">Show {{ desktopDayDuration + 1 }} Days</div>
        <input
          class="range-slider-input"
          type="range"
          min="3"
          max="6"
          step="1"
          pattern="\d*"
          title="daysToShow"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          [value]="desktopDayDuration"
          (mouseup)="updateWeekNumber($event)"
          (touchend)="updateWeekNumber($event)"
        />
      </div>
    </div>
  </div>

  <div class="roster-table">
    <table>
      <thead>
        <tr>
          <th *ngFor="let date of weekDays; let i = index" class="align-top">
            <div
              class="weekday mb-2"
              role="button"
              id="addRoster"
              (click)="addStaticSchedule(date)"
            >
              <div>
                <div>
                  {{ date | date : "yyyy-MM-dd" }}
                </div>
                <div>
                  {{ date | misc : "getWeekDay" }}
                </div>
              </div>
              <div *ngIf="isAdmin || isDispatchUser" class="addJob">+</div>
            </div>
            <ng-container
              *ngIf="
                (weatherData | misc : 'getWeatherFromDate' : date)?.Condition
                  ?.text
              "
            >
              <div
                class="p-0 text-center fw-semibold small-font cursor-pointer border border-1 rounded-1"
                (click)="
                  openWeatherTemplate(weatherInfoTemplate, weatherData, date)
                "
              >
                <ng-container
                  *ngIf="
                    (weatherData | misc : 'getWeatherFromDate' : date)
                      ?.Condition?.icon_id
                  "
                >
                  <img
                    width="28"
                    height="28"
                    class="me-2"
                    src="https://raw.githubusercontent.com/visualcrossing/WeatherIcons/58c79610addf3d4d91471abbb95b05e96fb43019/SVG/4th%20Set%20-%20Color/{{
                      (weatherData | misc : 'getWeatherFromDate' : date)
                        ?.Condition?.icon_id
                    }}.svg"
                  />
                </ng-container>

                {{
                  (weatherData | misc : "getWeatherFromDate" : date)?.Condition
                    ?.text
                }}
              </div>
              <div>
                @if(weatherData?.expanded){
                <div class="row row-cols-3">
                  @for(item of (weatherData | misc : "getWeatherFromDate" :
                  date) | keyvalue; track item){
                  <dl *ngIf="item?.key !== 'Condition'">
                    <dt>{{ item.key }}</dt>
                    <dd>
                      {{ item.value }}
                    </dd>
                  </dl>
                  }
                </div>
                }
              </div>
            </ng-container>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="groupByData?.length" id="rosterJobList">
        <tr *ngFor="let grpEvent of groupByData">
          <ng-container *ngIf="grpEvent?.event_data?.length">
            <td *ngFor="let date of weekDays">
              <div
                class="client-name fw-bold"
                *ngIf="hasEventOnDay(date, grpEvent)"
              >
                {{ grpEvent?.detail?.company_name }}
              </div>

              <div *ngFor="let schedule of grpEvent?.event_data">
                <div
                  class="scheduled-item"
                  (click)="
                    openEventDetailPage(schedule, date, choiceTemplateRef);
                    $event.stopImmediatePropagation()
                  "
                  *ngIf="
                    schedule?.event_dates?.includes(
                      date | date : 'yyyy-MM-dd'
                    ) &&
                    (isAdmin ||
                      isDispatchUser ||
                      isGuardPresentCheck(schedule, date)) &&
                    (schedule?.repeat_type !== 'once' ||
                      (schedule?.repeat_type == 'once' &&
                        (schedule?.start_day | date : 'yyyy-MM-dd') ===
                          (date | date : 'yyyy-MM-dd')))
                  "
                >
                  <!-- <div
                    class="conflict-detected"
                    *ngIf="isScheduleInConflict(date, schedule)"
                  >
                    Conflict Detected
                  </div> -->
                  <span
                    [ngStyle]="cardStyling?.status?.[schedule|misc:'getJobStatus':date]"
                    class="d-flex justify-content-center mb-1"
                  >
                    {{ schedule | misc : "getJobStatus" : date }}
                  </span>

                  <div>
                    <div class="d-flex justify-content-between">
                      <div class="fw-bold">
                        {{ schedule?.start_time }} -
                        {{ schedule?.end_time }}
                      </div>
                      <div>
                        {{ schedule | misc : "getTimeDifference" }}
                        Hrs
                      </div>
                      <div
                        *ngIf="
                          schedule
                            | misc
                              : 'trashIconIf'
                              : date
                              : isAdmin || isDispatchUser
                        "
                      >
                        <span
                          (click)="
                            openTemplate(
                              deleteScheduleTemplate,
                              schedule,
                              date
                            );
                            $event.stopImmediatePropagation()
                          "
                        >
                          <i class="fa-solid fa-ellipsis fs-5 me-2"></i>
                        </span>
                      </div>
                    </div>
                    <div class="fw-semibold">
                      <div class="d-flex justify-content-between my-1">
                        <div>
                          {{
                            schedule?.patrol_route_details
                              ? (schedule
                                | misc : "getPatrolCheckpointName" : date)
                              : (schedule?.job_details?.job_type | titlecase)
                          }}
                          <ng-container
                            *ngIf="schedule | misc : 'getJobServiceType' : date"
                          >
                            <span class="fw-normal">
                              -
                              {{ schedule | misc : "getJobServiceType" : date }}
                            </span>
                          </ng-container>
                        </div>

                        <div
                          *ngIf="schedule?.patrol_route_details"
                          class="me-2"
                        >
                          <i class="fa-solid fa-location-dot"></i>
                          {{
                            schedule | misc : "getPatrolCheckpointCount" : date
                          }}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="schedule?.job_details?.job_type">
                      <div>
                        {{ schedule?.job_details?.site_name }}
                      </div>
                    </div>

                    <ng-container *ngIf="isAdmin || isDispatchUser">
                      @if(( schedule | misc : 'getEventAssignees' : date :
                      isAdmin || isDispatchUser )?.length){
                      <div class="my-1">
                        <span
                          *ngFor="
                            let assignee of schedule
                              | misc
                                : 'getEventAssignees'
                                : date
                                : isAdmin || isDispatchUser;
                            let l = last
                          "
                        >
                          @if(isGuardInConflict(date, assignee?.id, schedule)){
                          <span
                            class="text-danger"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Conflict Detected"
                            >{{ assignee?.full_name }} (Conflict)</span
                          >
                          }@else{

                          <span class="">{{ assignee?.full_name }}</span>
                          }
                          {{ l ? "" : ", " }}
                        </span>
                      </div>

                      }@else{
                      <div class="text-danger fw-bold">Unassigned</div>
                      }
                    </ng-container>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>

} @else{
<!--mobile view-->
<div class="d-flex text-center my-1" id="rosterChange">
  <div class="w-25 p-1">
    <div (click)="previousWeek()" class="btn btn-secondary w-100">
      <i class="fas fa-angle-double-left"></i>
    </div>
  </div>
  <div class="w-25 p-1">
    <div (click)="previousDay()" class="btn btn-secondary w-100">
      <i class="fas fa-angle-left"></i>
    </div>
  </div>
  <div class="w-50 p-1">
    <input
      type="date"
      id="dateTimePicker"
      class="d-none"
      [(ngModel)]="mobileselectedDate"
      (change)="changeSelectedDate($event)"
    />
    <ng-container *ngIf="mobileselectedDate">
      <div (click)="openDatePicker()" class="btn btn-primary w-100">
        {{ mobileselectedDate | date : "d MMM" }}
        <span class="float-end">
          <i class="fa regular fa-calendar"></i>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="w-25 p-1">
    <div (click)="nextDay()" class="btn btn-secondary w-100">
      <i class="fas fa-angle-right"></i>
    </div>
  </div>
  <div class="w-25 p-1">
    <div (click)="nextWeek()" class="btn btn-secondary w-100">
      <i class="fas fa-angle-double-right"></i>
    </div>
  </div>
</div>

<div class="mb-4">
  <div
    id="rosterWeek"
    class="d-flex"
    (touchstart)="onTouchStart($event)"
    (touchend)="onTouchEnd($event)"
    [ngClass]="{
      animate: isAnimating
    }"
  >
    <div
      *ngFor="let date of weekDays"
      class="mobile-calendar"
      [ngStyle]="
        (date | date : 'yyyy-MM-dd') ===
        (mobileselectedDate | date : 'yyyy-MM-dd')
          ? {
              'background-color': 'var(--bs-primary)',
              'color': 'var(--bs-white)'
            }
          : ['Su', 'Sa'].includes(date | misc : 'getWeekDay')
          ? {
              'font-weight': '700'
            }
          : {}
      "
      (click)="formatMobileRosterSchedules(date)"
    >
      <div>
        {{ date | misc : "getWeekDay" }}
      </div>
      <div>
        {{ date | date : "dd" }}
      </div>
      <div>
        {{ date | date : "MMM" }}
      </div>
    </div>
  </div>
</div>
<ng-container
  *ngIf="
    mobileselectedDate &&
    (weatherData | misc : 'getWeatherFromDate' : mobileselectedDate)?.Condition
      ?.text
  "
>
  <div
    class="p-1 text-center fw-semibold border border-1 rounded-1 d-flex align-items-center justify-content-center mb-4"
    (click)="
      openWeatherTemplate(weatherInfoTemplate, weatherData, mobileselectedDate)
    "
  >
    <ng-container
      *ngIf="
        (weatherData | misc : 'getWeatherFromDate' : mobileselectedDate)
          ?.Condition?.icon_id
      "
    >
      <div>
        <img
          width="28"
          height="28"
          class="me-2"
          src="https://raw.githubusercontent.com/visualcrossing/WeatherIcons/58c79610addf3d4d91471abbb95b05e96fb43019/SVG/4th%20Set%20-%20Color/{{
            (weatherData | misc : 'getWeatherFromDate' : mobileselectedDate)
              ?.Condition?.icon_id
          }}.svg"
        />
      </div>
    </ng-container>

    {{
      (weatherData | misc : "getWeatherFromDate" : mobileselectedDate)
        ?.Condition?.text
    }}
  </div>
</ng-container>
<ng-container
  *ngIf="
    conflictData?.length &&
    (isAdmin || isDispatchUser) &&
    mobileRosterScheduleData?.length
  "
>
  <div
    class="text-center mb-4"
    (click)="openConflictTemplate(conflictTemplate)"
  >
    <button class="btn btn-danger w-100 btn-sm">Conflicts</button>
  </div>
</ng-container>

<div
  [ngClass]="mobileRosterScheduleData?.length ? ' fixed-btn-py' : ''"
  id="rosterJobList"
>
  <!--data section-->
  <div *ngFor="let schedule of mobileRosterScheduleData">
    <div
      (click)="
        openEventDetailPage(schedule, mobileselectedDate, choiceTemplateRef)
      "
      class="scheduled-item"
    >
      <span
        [ngStyle]="cardStyling?.status?.[schedule|misc:'getJobStatus':mobileselectedDate]"
        class="d-flex justify-content-center mb-1 py-1"
      >
        {{ schedule | misc : "getJobStatus" : mobileselectedDate }}
      </span>

      <div>
        <div class="d-flex justify-content-between">
          <div class="fw-bold">
            {{ schedule?.start_time }} -
            {{ schedule?.end_time }}
          </div>
          <div>
            {{ schedule | misc : "getTimeDifference" }}
            Hrs
          </div>
          <div
            *ngIf="
              schedule
                | misc
                  : 'trashIconIf'
                  : mobileselectedDate
                  : isAdmin || isDispatchUser
            "
          >
            <span
              (click)="
                openTemplate(
                  deleteScheduleTemplate,
                  schedule,
                  mobileselectedDate
                );
                $event.stopImmediatePropagation()
              "
            >
              <i class="fa-solid fa-ellipsis fs-5 me-2"></i>
            </span>
          </div>
        </div>
        <div class="fw-semibold">
          <div class="d-flex justify-content-between my-1">
            <div>
              {{
                schedule?.patrol_route_details
                  ? (schedule
                    | misc : "getPatrolCheckpointName" : mobileselectedDate)
                  : (schedule?.job_details?.job_type | titlecase)
              }}
              <ng-container
                *ngIf="
                  schedule | misc : 'getJobServiceType' : mobileselectedDate
                "
              >
                <span class="fw-normal">
                  -
                  {{
                    schedule | misc : "getJobServiceType" : mobileselectedDate
                  }}
                </span>
              </ng-container>
            </div>

            <div *ngIf="schedule?.patrol_route_details" class="me-2">
              <i class="fa-solid fa-location-dot"></i>
              {{
                schedule
                  | misc : "getPatrolCheckpointCount" : mobileselectedDate
              }}
            </div>
          </div>
        </div>
        <div *ngIf="schedule?.job_details?.job_type">
          <div>
            {{ schedule?.job_details?.site_name }}
            <span class="text-secondary"
              >[{{ schedule?.job_details?.company_name }}]</span
            >
          </div>
        </div>

        <ng-container *ngIf="isAdmin || isDispatchUser">
          @if(( schedule | misc : 'getEventAssignees' : mobileselectedDate :
          isAdmin || isDispatchUser )?.length){
          <div class="my-1">
            <span
              *ngFor="
                let assignee of schedule
                  | misc
                    : 'getEventAssignees'
                    : mobileselectedDate
                    : isAdmin || isDispatchUser;
                let l = last
              "
            >
              @if(isGuardInConflict(mobileselectedDate, assignee?.id,
              schedule)){
              <span
                class="text-danger"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Conflict Detected"
                >{{ assignee?.full_name }} (Conflict)</span
              >
              }@else{

              <span class="">{{ assignee?.full_name }}</span>
              }
              {{ l ? "" : ", " }}
            </span>
          </div>

          }@else{
          <div class="text-danger fw-bold">Unassigned</div>
          }
        </ng-container>
      </div>
    </div>
  </div>
  @if(!mobileRosterScheduleData?.length && mobileselectedDate){
  <!-- NO job present for the date-->
  <div class="centered">
    <div class="alert alert-secondary">
      No Jobs rostered for {{ mobileselectedDate | customDate : "d MMM y" }}
    </div>
  </div>
  }
</div>

<div class="mt-1 mx-1" *ngIf="isAdmin || isDispatchUser">
  <div class="fixed-add-btn">
    <button
      id="searchRosterJobs"
      class="btn btn-lg btn-secondary"
      (click)="openSearchTemplate(searchTemplateRef)"
    >
      <span> <i class="fa-solid fa-magnifying-glass-plus"></i> </span>
    </button>
    <button
      id="addRoster"
      class="btn btn-lg btn-primary"
      (click)="addStaticSchedule(mobileselectedDate)"
    >
      <span><i class="fa-solid fa-plus"></i></span>
    </button>
  </div>
</div>
}

<ng-template
  #deleteScheduleTemplate
  let-deleteScheduleTemplate="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      <span class="float-end" (click)="deleteScheduleTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="mt-3" *ngIf="data?.repeat_type !== 'once'">
        <div class="form-group ms-2 mb-5">
          <!-- Radio Group Label, if needed -->

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="radio"
              name="deleteOption"
              id="deleteOption1"
              [value]="1"
              [(ngModel)]="deleteOptionSelected"
              [checked]="deleteOptionSelected === 1"
            />
            <label class="form-check-label" for="deleteOption1">
              Only this job
            </label>
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="deleteOption"
              id="deleteOption2"
              [value]="2"
              [(ngModel)]="deleteOptionSelected"
              [checked]="deleteOptionSelected === 2"
              autocomplete="off"
            />
            <label class="form-check-label" for="deleteOption2">
              This and all future occurrences
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="data?.repeat_type == 'once'">
        <div class="alert">Are you sure you want to delete this job?</div>
      </div>
    </div>
    <div class="row row-cols-2">
      <div>
        <button
          class="btn btn-secondary btn-lg w-100"
          (click)="deleteScheduleTemplate.close()"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          class="btn btn-lg btn-danger w-100"
          (click)="deleteScheduleEvents(data); deleteScheduleTemplate.close()"
        >
          Delete Job
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #conflictTemplate let-conflictTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Conflicts
      <span class="float-end" (click)="conflictTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div *ngFor="let conflictItem of conflictData" class="mb-4">
      <div class="text-center ps-1 fw-bold">
        {{ conflictItem?.eventDate | customDate : "EEEE d MMM y" }}
      </div>
      <div *ngFor="let conflict of conflictItem?.conflictInfo | keyvalue">
        <div class="ps-1 mb-1 fw-bold">
          {{ getConflictAssigneeInfo(conflict?.value)?.full_name }}
        </div>
        <div class="row row-cols-1 row-cols-lg-2">
          <div
            *ngFor="
              let conflictSchedule of conflict?.value
                | misc : 'getConflictSchedules'
            "
          >
            <div
              (click)="
                openEventDetailPage(
                  conflictSchedule,
                  conflictItem?.eventDate,
                  choiceTemplateRef
                );
                conflictTemplate.close()
              "
              class="conflict-item"
              *ngIf="
                !conflictSchedule?.exception_dates?.includes(
                  conflictItem?.eventDate | date : 'yyyy-MM-dd'
                )
              "
            >
              <div class="flex-grow-1">
                <span
                  [ngStyle]="cardStyling?.status?.[conflictSchedule|misc:'getJobStatus': conflictItem?.eventDate]"
                  class="d-flex justify-content-center mb-1"
                >
                  {{
                    conflictSchedule
                      | misc : "getJobStatus" : conflictItem?.eventDate
                  }}
                </span>

                <div>
                  <div class="d-flex justify-content-between">
                    <div class="fw-bold">
                      {{ conflictSchedule?.start_time }} -
                      {{ conflictSchedule?.end_time }}
                    </div>
                    <div>
                      {{ conflictSchedule | misc : "getTimeDifference" }}
                      Hrs
                    </div>
                  </div>
                  <div class="fw-semibold">
                    <div class="d-flex justify-content-between my-1">
                      <div>
                        {{
                          conflictSchedule?.patrol_route_details
                            ? (conflictSchedule
                              | misc
                                : "getPatrolCheckpointName"
                                : conflictItem?.eventDate)
                            : (conflictSchedule?.job_details?.job_type
                              | titlecase)
                        }}
                        <ng-container
                          *ngIf="
                            conflictSchedule
                              | misc
                                : 'getJobServiceType'
                                : conflictItem?.eventDate
                          "
                        >
                          <span class="fw-normal">
                            -
                            {{
                              conflictSchedule
                                | misc
                                  : "getJobServiceType"
                                  : conflictItem?.eventDate
                            }}
                          </span>
                        </ng-container>
                      </div>

                      <div
                        *ngIf="conflictSchedule?.patrol_route_details"
                        class="me-2"
                      >
                        <i class="fa-solid fa-location-dot"></i>
                        {{
                          conflictSchedule
                            | misc
                              : "getPatrolCheckpointCount"
                              : conflictItem?.eventDate
                        }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="conflictSchedule?.job_details?.job_type">
                    <div>
                      {{ conflictSchedule?.job_details?.site_name }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2 d-flex mx-auto" *ngIf="conflictItem?.eventDate">
                <button
                  class="btn btn-primary w-100"
                  (click)="
                    reassigneGuards(
                      modifyAssigneeRef,
                      conflictSchedule,
                      conflictItem?.eventDate,
                      getConflictAssigneeInfo(conflict?.value)
                    );
                    $event.stopImmediatePropagation();
                    conflictTemplate.close()
                  "
                >
                  Assign to another guard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Dialogue for assign users -->
<ng-template #modifyAssigneeRef let-data let-modifyAssigneeRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Assigned Guards
      <span class="float-end" (click)="modifyAssigneeRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <gtapp-auto-complete
        [data]="guardsList"
        placeHolder="Search/Select Assignee"
        searchKeyword="full_name"
        [initialValue]="assineeNameValue"
        [itemTemplate]="assigneeTemplate"
        (inputChanged)="getAssignees($event, data)"
        (selected)="onAssigneeSelect($event, data)"
        inputId="conflictAssigneeId"
      >
      </gtapp-auto-complete>
      <ng-template #assigneeTemplate let-guard>
        <div>
          {{ guard?.full_name ? guard.full_name : guard.email }}

          <span [attr.aria-label]="'User with a valid state license'">
            <i class="fa-solid fa-check-double"></i>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #choiceTemplateRef let-data let-choiceTemplateRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Choose
      <span class="float-end" (click)="choiceTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="row row-cols-1 row-cols-lg-2 gy-2 mt-2">
        <div
          class="col"
          (click)="
            data?.schedule?.job_details?.job_type
              ? openJobDetailPage(data?.schedule, data?.date)
              : openPatrolRoutePage(data?.schedule, data?.date);
            choiceTemplateRef.close()
          "
        >
          <button class="btn btn-lg btn-secondary w-100">
            Open this
            {{
              data?.schedule?.job_details?.job_type
                ? data?.schedule?.job_details?.job_type
                : ("Patrol" | titlecase)
            }}
            job
          </button>
        </div>
        <div
          class="col"
          (click)="
            choiceTemplateRef.close(); editSchedule(data?.schedule, data?.date)
          "
        >
          <button class="btn btn-lg btn-secondary w-100">Edit Schedule</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchTemplateRef let-data let-searchTemplateRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Search Jobs
      <span class="float-end" (click)="searchTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="mt-1 form-floating">
        <input
          class="form-control input-font-sm"
          placeholder="Pick Date Range"
          (click)="openRangePicker()"
          [value]="dateRangeValue"
        />
        <label for="date_range">Pick Date Range</label>
      </div>
      <div class="form-floating my-3" *ngIf="searchDateRange">
        <input
          type="text"
          class="form-control"
          id="filterJobsInput"
          placeholder="Filter Jobs"
          (keyup)="filterJobs($event)"
        />
        <label for="filterJobsInput">Filter Jobs</label>
      </div>

      <div *ngIf="!searchResults?.length" class="my-3">
        <div role="alert" class="alert alert-secondary">
          No Records Match Your Search Criteria
        </div>
      </div>

      <div *ngFor="let searchItem of searchResults">
        <h4 class="text-center mt-3">
          {{ searchItem?.eventDate | customDate : "d MMM y" }}
        </h4>
        <div class="row row-cols-1 row-cols-lg-2">
          <div *ngFor="let schedule of searchItem?.dayJobs">
            <div
              (click)="
                openEventDetailPage(
                  schedule,
                  searchItem?.eventDate,
                  choiceTemplateRef
                );
                searchTemplateRef.close()
              "
              class="scheduled-item"
            >
              <span
                [ngStyle]="cardStyling?.status?.[schedule|misc:'getJobStatus':searchItem?.eventDate]"
                class="d-flex justify-content-center mb-1"
              >
                {{ schedule | misc : "getJobStatus" : searchItem?.eventDate }}
              </span>

              <div>
                <div class="d-flex justify-content-between">
                  <div class="fw-bold">
                    {{ schedule?.start_time }} -
                    {{ schedule?.end_time }}
                  </div>
                  <div>
                    {{ schedule | misc : "getTimeDifference" }}
                    Hrs
                  </div>
                  <div
                    *ngIf="
                      schedule
                        | misc
                          : 'trashIconIf'
                          : searchItem?.eventDate
                          : isAdmin || isDispatchUser
                    "
                  >
                    <span
                      (click)="
                        openTemplate(
                          deleteScheduleTemplate,
                          schedule,
                          searchItem?.eventDate
                        );
                        $event.stopImmediatePropagation()
                      "
                    >
                      <i class="fa-solid fa-ellipsis fs-5 me-2"></i>
                    </span>
                  </div>
                </div>
                <div class="fw-semibold">
                  <div class="d-flex justify-content-between my-1">
                    <div>
                      {{
                        schedule?.patrol_route_details
                          ? (schedule
                            | misc
                              : "getPatrolCheckpointName"
                              : searchItem?.eventDate)
                          : (schedule?.job_details?.job_type | titlecase)
                      }}
                      <ng-container
                        *ngIf="
                          schedule
                            | misc : 'getJobServiceType' : searchItem?.eventDate
                        "
                      >
                        <span class="fw-normal">
                          -
                          {{
                            schedule
                              | misc
                                : "getJobServiceType"
                                : searchItem?.eventDate
                          }}
                        </span>
                      </ng-container>
                    </div>

                    <div *ngIf="schedule?.patrol_route_details" class="me-2">
                      <i class="fa-solid fa-location-dot"></i>
                      {{
                        schedule
                          | misc
                            : "getPatrolCheckpointCount"
                            : searchItem?.eventDate
                      }}
                    </div>
                  </div>
                </div>
                <div *ngIf="schedule?.job_details?.job_type">
                  <div>
                    {{ schedule?.job_details?.site_name }}
                  </div>
                </div>

                <ng-container *ngIf="isAdmin || isDispatchUser">
                  @if(( schedule | misc : 'getEventAssignees' :
                  searchItem?.eventDate : isAdmin || isDispatchUser )?.length){
                  <div class="my-1">
                    <span
                      *ngFor="
                        let assignee of schedule
                          | misc
                            : 'getEventAssignees'
                            : searchItem?.eventDate
                            : isAdmin || isDispatchUser;
                        let l = last
                      "
                    >
                      @if(isGuardInConflict(searchItem?.eventDate, assignee?.id,
                      schedule)){
                      <span
                        class="text-danger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Conflict Detected"
                        >{{ assignee?.full_name }} (Conflict)</span
                      >
                      }@else{

                      <span class="">{{ assignee?.full_name }}</span>
                      }
                      {{ l ? "" : ", " }}
                    </span>
                  </div>

                  }@else{
                  <div class="text-danger fw-bold">Unassigned</div>
                  }
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #weatherInfoTemplate let-weatherInfoTemplate="dialogRef" let-data>
  <div class="dialog-box card">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <div class="fw-semibold" *ngIf="weatherAddressInfo?.city_name">
            {{ weatherAddressInfo?.city_name
            }}{{
              weatherAddressInfo?.state_code
                ? ", " + weatherAddressInfo?.state_code
                : ""
            }}
          </div>

          <div>
            {{ data?.selectedDate | customDate : "EEEE d MMM yyyy" }}
          </div>
        </div>
        <div>
          <span (click)="weatherInfoTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center mb-4">
      <div>
        {{data?.weatherData?.['Description']}}
      </div>
      <ng-container *ngIf="data?.weatherData?.['Condition']?.icon_id">
        <div>
          <img
            width="40"
            height="40"
            class="ms-3"
            src="https://raw.githubusercontent.com/visualcrossing/WeatherIcons/58c79610addf3d4d91471abbb95b05e96fb43019/SVG/4th%20Set%20-%20Color/{{data?.weatherData?.['Condition']?.icon_id}}.svg"
          />
        </div>
      </ng-container>
    </div>

    <div class="row row-cols-3">
      @for(item of data?.weatherKeys; track item){

      <dl>
        <dt>{{ item }}</dt>
        <dd>
          {{ data?.weatherData?.[item] }}
        </dd>
      </dl>
      }
    </div>
    <div class="small text-end text-secondary">
      Weather provided by www.visualcrossing.com
    </div>
  </div>
</ng-template>
