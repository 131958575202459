import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, timeout } from 'rxjs';
import { LoadingSpinnerService } from './loading-spinner.service';
import { getDistanceUsingHaversine } from '../../../global.variable';
@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  deviceInfoSubject = new BehaviorSubject({});

  appStatus: boolean = true;
  // param to reverse the pages status
  locationDisabled: boolean = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private spinnerService: LoadingSpinnerService
  ) {
    this.onLineOfflineCheck();
    window.addEventListener('online', () => {
      // adding a delay so that the device gets connected properly before calling the server
      setTimeout(() => {
        this.onLineOfflineCheck();
      }, 2000);
    });
    window.addEventListener('offline', () => {
      this.onLineOfflineCheck();
    });
    this.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.appStatus = value?.appStatus;
      }
    });
  }
  isPhoneIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
  isDevicePWA() {
    return Boolean(window.matchMedia('(display-mode: standalone)').matches);
  }
  onLineOfflineCheck() {
    this.deviceInfoSubject.next({
      appStatus: navigator.onLine && this.appStatus,
    });
  }
  isRegistrationComplete() {
    // method to check if the registartion is complete. T
    // he user may not have given access to location so the spinnerhide will interput the subscription apis
    const firstTimeSubscriber = localStorage.getItem('firstTimeSubscriber');
    return firstTimeSubscriber !== 'true';
  }
  async deviceHasGivenGpsPermission() {
    try {
      const permission = await navigator.permissions.query({
        name: 'geolocation' as PermissionName,
      });

      return Boolean(['granted'].includes(permission.state));
    } catch (error) {
      return false;
    }
  }

  async getApiHeadersAndExtras(token: string) {
    return new Promise((resolve) => {
      let headerDict: any = {
        Authorization: `Bearer ${token}`,
        timeoffset: String(new Date().getTimezoneOffset()),
      };

      try {
        const diffSeconds: any = 30; // 30 seconds
        const JsonGpsRecord = JSON.parse(
          localStorage.getItem('gpsRecord') || '{}'
        );
        const lastSavedGpsRecord = JSON.parse(
          localStorage.getItem('lastSavedGpsRecord') || '{}'
        );
        // passing gps in all the api calls to track user. if the date time is same as the previous due to simultaneous api call, discard that. and also when the gps has not changed
        if (
          JsonGpsRecord?.recorded_at &&
          (!lastSavedGpsRecord?.recorded_at ||
            (lastSavedGpsRecord?.recorded_at &&
              JsonGpsRecord?.recorded_at >=
                lastSavedGpsRecord?.recorded_at + diffSeconds &&
              (lastSavedGpsRecord?.gps?.lat !== JsonGpsRecord?.gps?.lat ||
                lastSavedGpsRecord?.gps?.lon !== JsonGpsRecord?.gps?.lon)))
        ) {
          localStorage.setItem(
            'lastSavedGpsRecord',
            JSON.stringify(JsonGpsRecord)
          );
          headerDict['gpsRecord'] = localStorage.getItem('gpsRecord');
        }
      } catch (error) {
        throw error;
      } finally {
        if (this.isRegistrationComplete()) this.updateGpsCache();
        resolve({
          headerDict: headerDict,
          reqBody: {
            delayed_event: this.appStatus && navigator.onLine,
          },
        });
      }
    });
  }

  async setIp() {
    try {
      this.getAwaitIP();
    } catch (error) {
      // Handle the error (e.g., log it or show a message)
      throw error;
    }
  }
  async handleIpApiError() {
    return await new Promise(async (resolve, reject) => {
      if (window.localStorage.getItem('ipAddress')) {
        resolve(window.localStorage.getItem('ipAddress'));
      }
      try {
        const TIMEOUT_IN_MS = 5000;
        this.backupApiCall()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ipAddress) {
                window.localStorage.setItem('ipAddress', resp.ipAddress);
                resolve(resp.ipAddress);
              }
            },
            error: (err) => {
              if (err.name === 'TimeoutError') {
                resolve('null');
              } else {
                resolve('null');
              }
            },
          });
      } catch (error) {
        resolve('null');
        throw error;
      }
    });
  }
  backupApiCall(): Observable<any> {
    return this.http.get('https://freeipapi.com/api/json');
  }
  getIP(): Observable<any> {
    return this.http.get('https://jsonip.com');
  }
  async getAwaitIP() {
    const TIMEOUT_IN_MS = 5000;
    return await new Promise(async (resolve, reject) => {
      try {
        this.getIP()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ip) {
                window.localStorage.setItem('ipAddress', resp.ip);
                resolve(resp.ip);
              }
            },
            error: async (err) => {
              if (err.name === 'TimeoutError') {
                resolve(await this.handleIpApiError());
              } else {
                // Handle other errors
              }
            },
          });
        setTimeout(() => {
          if (!window.localStorage.getItem('ipAddress')) {
            this.handleIpApiError();
          }
        }, 5000);
      } catch (error) {
        resolve(await this.handleIpApiError());
        throw error;
      }
    });
  }
  getGPS() {
    return this.http.get('https://freeipapi.com/api/json');
  }
  async getAwaitFreeGPS() {
    return await new Promise((resolve, reject) => {
      this.getGPS().subscribe((resp: any) =>
        resolve({
          lat: resp?.latitude,
          lon: resp?.longitude,
          source: 2,
        })
      );
    });
  }

  async watchPositionWithLowAccuracy(regardTimeOut = false) {
    const startTime = new Date().getTime();
    return await new Promise((resolve, reject) => {
      let lowAccuracyWatchId = navigator.geolocation.watchPosition(
        (position) => {
          // if we are getting a postion we can stop the watchPostion then and there.
          navigator.geolocation.clearWatch(lowAccuracyWatchId);
          this.updateLocalStorageGPSFetchTimes(startTime, 2);
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 1,
          });
        },
        async (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // the chances of this occuring is very low as if the
            // permission is denied it will be blocked in the high accuracy watch postion method itself and wont reach here.
            // Still we will stop the low accuracy watch postion and update the status
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
            if (this.isRegistrationComplete()) this.spinnerService.hide();
            this.deviceInfoSubject.next({
              permissionStatus: 'locationDisabled',
            });
            this.locationDisabled = true;
          } else {
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
            // These include the POSITION_UNAVAILABLE error, which is hard to produce.
            // So will use the same strategy as that of the other error possible ie TIMEOUT.
            // The below logic is mainly intended for the TIMEOUT scenario which can occur for multiple reasons
            // 1. Offline and doesn't have a clear sky view https://github.com/Baseflow/flutter-geolocator/issues/1312
            // 2. User gave browser permission but turned off gps at phone level
            // we will stop this watch position and stop wasting time and
            // check if the localStorage value is stale and pass it if its not other wise update the user that gps is not available
            const JsonGpsRecord = JSON.parse(
              localStorage.getItem('gpsRecord') || '{}'
            );

            // check if the record is stale ie > 5 minutes. if so pass that value. T
            // his is done mainly for the Offline and doesn't have a clear sky view https://github.com/Baseflow/flutter-geolocator/issues/1312

            // also check regardTimeOut = true => we dont need to take the value from localStorage in that case.
            // This will be true for the gps polling method ( updateGpsCache() )
            if (regardTimeOut) return;

            const checkCondition =
              JsonGpsRecord?.gps?.lat &&
              JsonGpsRecord?.recorded_at &&
              (new Date().getTime() - JsonGpsRecord?.recorded_at * 1000) /
                60000 <
                5;

            if (checkCondition) {
              resolve({
                lat: JsonGpsRecord?.gps?.lat,
                lon: JsonGpsRecord?.gps?.lon,
                source: 3,
                timeStamp: JsonGpsRecord?.recorded_at,
                orginalSource: JsonGpsRecord?.gps?.source,
              });
            } else {
              // Else we will just tell the user gps is not available
              this.spinnerService.hide();
              this.deviceInfoSubject.next({
                permissionStatus: 'locationDisabled',
              });
              this.locationDisabled = true;
            }
          }
        },
        {
          timeout: 1500,
          enableHighAccuracy: false,
          maximumAge: 5000,
        }
      );
    });
  }
  updateLocalStorageGPSFetchTimes(startTime: any, _type: any) {
    const _key = _type === 1 ? 'GPSFetchTimesHigh' : 'GPSFetchTimesLow';

    const existingData = JSON.parse(localStorage.getItem(_key) || '{}');

    const today = new Date().setHours(0, 0, 0, 0);
    const data = {
      timeTook: Math.round(new Date().getTime() - startTime),
      currentTime: new Date().getTime(),
    };

    if (!existingData[today]) {
      existingData[today] = { data: [], stats: { min: data, max: data } };
    }
    existingData[today]['data'].push(data);

    if (data.timeTook > existingData[today]?.stats?.max?.timeTook) {
      existingData[today].stats.max = data;
    }
    if (data.timeTook < existingData[today]?.stats?.min?.timeTook) {
      existingData[today].stats.min = data;
    }
    localStorage.setItem(_key, JSON.stringify(existingData));
  }
  async watchPositionWithHighAccuracy(
    regardTimeOut = false,
    gpsOptional = false,
    count = 0
  ) {
    const startTime = new Date().getTime();
    return await new Promise((resolve, reject) => {
      let highAccuracyWatchId = navigator.geolocation.watchPosition(
        (position) => {
          // if we are getting a postion we can stop the watchPostion then and there.
          navigator.geolocation.clearWatch(highAccuracyWatchId);
          this.updateLocalStorageGPSFetchTimes(startTime, 1);

          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 0,
          });
        },
        async (error) => {
          if (gpsOptional) {
            // pass back empty dict so that backend wont cause any issue.
            // Made so that we will start allowing apis to be called without gps
            resolve({});
          } else if (error.code === error.PERMISSION_DENIED) {
            // the user has denied the permission at the browser level,
            // which means there's nothing we can do about it further
            navigator.geolocation.clearWatch(highAccuracyWatchId);
            // we will just stop the watchPostion and then update the status
            if (this.isRegistrationComplete()) this.spinnerService.hide();
            this.deviceInfoSubject.next({
              permissionStatus: 'locationDisabled',
            });
            this.locationDisabled = true;
          } else {
            count += 1;
            // These include the POSITION_UNAVAILABLE error, which is hard to produce.
            // So will use the same strategy as that of the other error possible ie TIMEOUT.

            // The below logic is mainly intended for the TIMEOUT scenario which can occur for multiple reasons
            // 1. Offline and doesn't have a clear sky view https://github.com/Baseflow/flutter-geolocator/issues/1312
            // 2. User gave browser permission but turned off gps at phone level

            // we will stop this watchPostion and try calling the watchPostion
            // with high accuracy one more time. if that also fails we will go to low accuracy.
            if (count < 2) {
              navigator.geolocation.clearWatch(highAccuracyWatchId);
              resolve(
                await this.watchPositionWithHighAccuracy(
                  regardTimeOut,
                  gpsOptional,
                  count
                )
              );
            } else {
              // here we will call the low accuracy watchposition after stopping this watchPostion
              navigator.geolocation.clearWatch(highAccuracyWatchId);
              // we dont need to pass gpsOptional,
              // because it will be executed in the first if. no need to wait for these if gpsOptional=true
              resolve(await this.watchPositionWithLowAccuracy(regardTimeOut));
            }
          }
        },
        {
          timeout: 1000,
          enableHighAccuracy: true,
          maximumAge: 5000,
        }
      );
    });
  }
  async getIp() {
    return new Promise(async (resolve) => {
      resolve(
        window.localStorage.getItem('ipAddress')
          ? window.localStorage.getItem('ipAddress')
          : await this.getAwaitIP()
      );
    });
  }

  async setAndGetDeviceInfo() {
    return await new Promise(async (resolve) => {
      let deviceInfo = this.deviceService.getDeviceInfo();
      window.localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
      resolve(deviceInfo);
    });
  }
  async getDeviceInfo() {
    return new Promise(async (resolve) => {
      try {
        const deviceInfo: any = window.localStorage.getItem('deviceInfo')
          ? window.localStorage.getItem('deviceInfo')
          : await this.setAndGetDeviceInfo();
        resolve(JSON.parse(deviceInfo));
      } catch (err) {
        resolve(this.deviceService.getDeviceInfo());
      }
    });
  }
  getGpsCoordinates = async (regardTimeOut = false, gpsOptional = false) => {
    return await this.watchPositionWithHighAccuracy(regardTimeOut, gpsOptional);
  };
  updateGpsCache() {
    this.getGpsCoordinates(true).then((gps: any) => {
      let gpsRecord = { gps: gps, recorded_at: new Date().getTime() / 1000 };
      if (gps?.lat) {
        this.checkGeoFenceAlertData(gps);
        localStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
        if (this.locationDisabled) {
          this.locationDisabled = false;
          this.deviceInfoSubject.next({
            permissionStatus: 'locationEnabled',
          });
        }
      }
    });
  }
  async getDeviceDetail(gpsCondition = 'must') {
    return new Promise(async (resolve) => {
      let gps: any = {};
      if (gpsCondition === 'must') {
        gps = await this.getGpsCoordinates();
      } else if (gpsCondition === 'optional') {
        gps = await this.getGpsCoordinates(false, true);
        // may return empty dicts
      }
      if (gps?.lat && gps?.lon) {
        // may return empty dicts in case of optional
        let gpsRecord = {
          gps: gps,
          recorded_at: new Date().getTime() / 1000,
        };
        localStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
      }
      const deviceInfo: any = await this.getDeviceInfo();
      resolve({
        gps: {
          ...gps,
          ip: await this.getIp(),
        },
        ...deviceInfo,
      });
    });
  }

  checkGeoFenceAlertData(userLocation: any) {
    const geoFenceAlertData = JSON.parse(
      localStorage.getItem(btoa('geoFenceAlertData')) || '[]'
    );

    function fetchHaversineDistance(alertData: any) {
      const haversineDistance: any = getDistanceUsingHaversine(
        userLocation?.lat,
        alertData?.gps?.lat,
        userLocation?.lon,
        alertData?.gps?.lon
      );
      return haversineDistance * 1000;
    }

    const now = new Date().getTime();
    const alertData = geoFenceAlertData
      ?.map((alertData: any) => {
        const haversineDistance = fetchHaversineDistance(alertData);
        return { ...alertData, haversineDistance: haversineDistance };
      })
      .filter(
        (alertData: any) =>
          alertData?.startDateTime <= now &&
          now <= alertData?.endDateTime &&
          alertData?.haversineDistance > Number(alertData?.geoFenceDistance)
      );
    if (!alertData?.length) {
      // removing this value because if the user goes inside, we will restart the check.
      sessionStorage.removeItem('geoFenceLastAlertTime');
    }
    this.deviceInfoSubject.next({
      geoFenceAlert: alertData,
    });
  }
}
