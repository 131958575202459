<div class="fw-bold mb-2">Add Checkpoint</div>
<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <form [formGroup]="checkPointForm">
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 gy-2 mb-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-secondary btn-lg w-100"
              [routerLink]="['/checkpoints']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="isFirstStepInvalid()"
              (click)="
                currentStep = 2;
                checkPointForm.value?.site_id ? null : shiftFocus('siteId')
              "
            >
              Next
            </button>
          </div>
        </div>
        <gtapp-input-field
          dtValue="Checkpoint Name"
          inputId="cpName"
          [maxLength]="100"
          (blurEmit)="checkPointForm.controls['name'].setValue($event)"
        >
        </gtapp-input-field>

        <gtapp-address-lookup
          [addressForm]="checkPointForm"
          (updateAddress)="addressSelected($event)"
          (lookupNewAddress)="$event ? (showMap = false) : null"
        >
        </gtapp-address-lookup>

        <div *ngIf="showMap && latLon && !largeView" class="mt-2">
          <gtapp-map-leaflet
            [latLon]="latLon"
            [mapId]="'mapid'"
            [changableRadius]="true"
            [cursorDraggable]="true"
            (emitData)="emitData($event)"
            (updateMinDistance)="updateMinDistance($event)"
          ></gtapp-map-leaflet>
          <div class="text-center small">
            <em> Drag the marker to set coordinates </em>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 2">
        <div class="mb-2" *ngIf="!checkPointForm.value.site_id">
          Select the site this checkpoint belongs to
        </div>

        <div *ngIf="checkPointForm.value.site_id" class="mb-2">
          <dl>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Site</dt>
                <dd>
                  {{ checkPointForm.value.site }}
                  <div>[{{ checkPointForm.value.company }}]</div>
                </dd>
              </div>
              <div>
                <span (click)="editField('site')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
          </dl>
        </div>
        <div class="mb-3" *ngIf="!checkPointForm.value.site_id">
          <gtapp-auto-complete
            [data]="siteListData"
            placeHolder="Site"
            searchKeyword="company_name"
            [initialValue]="checkPointForm.value.site"
            [itemTemplate]="siteTemplate"
            [addInitialKey]="true"
            (inputChanged)="onSiteSearch($event)"
            (selected)="onSiteSelect($event)"
            inputId="siteId"
            [enableCloseOption]="true"
            (fieldCleared)="editField('site')"
          >
          </gtapp-auto-complete>
        </div>

        <div class="mb-2"></div>

        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div class="fw-medium">
              {{ item?.company_name }} [{{ item?.company?.company_name }}]
            </div>

            <div class="small-font text-secondary">
              {{ item?.address?.full_address }}
            </div>
          </div>
          }
        </ng-template>

        <div class="form-floating">
          <input
            class="form-control"
            id="description"
            formControlName="description"
            type="text"
            placeholder="Description (Optional)"
          /><label for="inputAssignee">Description (Optional)</label>
        </div>
        <div class="row row-cols-2 mt-2">
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-secondary btn-lg w-100"
              (click)="currentStep = 1; goBack()"
            >
              Back
            </button>
          </div>
          <div class="col" *ngIf="availableUserPrompts?.length">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              [disabled]="!checkPointForm.valid"
              (click)="currentStep = 3"
            >
              Next
            </button>
          </div>
          <div class="col" *ngIf="!availableUserPrompts?.length">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              [disabled]="!checkPointForm.valid || addBtnPressed"
              (click)="onClickSubmit()"
            >
              Add
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 3">
        <span>Assign Post Scan Prompt</span>

        <gtapp-auto-complete
          [data]="userPromptsSearchResults"
          placeHolder="Search Prompt"
          searchKeyword="name"
          [itemTemplate]="userPromptLookupTemplate"
          (inputChanged)="searchPrompts($event)"
          (selected)="addSelectedUserPrompt($event)"
        >
        </gtapp-auto-complete>
        <ng-template #userPromptLookupTemplate let-item>
          <div>{{ item?.name }}</div>
        </ng-template>

        <div>
          <table class="table">
            <tbody cdkDropList (cdkDropListDropped)="dropUserPrompt($event)">
              <tr
                *ngFor="let userPrompt of availableUserPrompts"
                class="cursor-pointer"
                (click)="addRemoveUserPrompt(userPrompt)"
              >
                <td class="py-3">
                  <div class="d-flex">
                    <div class="form-floating">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="userPrompt?.selected"
                      />
                    </div>

                    <div
                      class="ms-2 d-flex"
                      cdkDrag
                      [cdkDragDisabled]="!userPrompt?.selected"
                    >
                      <div
                        cdkDragHandle
                        *ngIf="
                          userPrompt?.selected &&
                          selectedUserPrompts?.length > 1
                        "
                      >
                        <i class="fa-solid fa-bars cursor-move"></i>
                      </div>
                      <div class="ms-2">
                        {{ userPrompt?.name }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="mt-3"
          *ngIf="
            userPromptsTotalPages > 1 &&
            userPromptsTotalCount != availableUserPrompts?.length
          "
        >
          <button
            type="button"
            class="btn btn-secondary btn-lg"
            (click)="onLoadMore()"
          >
            Load More
          </button>
        </div>

        <div class="row row-cols-2 gy-2 mt-4">
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-lg btn-secondary w-100"
              (click)="currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!checkPointForm.valid || addBtnPressed"
              (click)="onClickSubmit()"
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="col">
    <div *ngIf="showMap && latLon && largeView">
      <gtapp-map-leaflet
        [latLon]="latLon"
        mapId="addCpLargeMap"
        [changableRadius]="true"
        [cursorDraggable]="true"
        (emitData)="emitData($event)"
        (updateMinDistance)="updateMinDistance($event)"
      ></gtapp-map-leaflet>

      <div class="text-center small">
        <em> Drag the marker to set coordinates </em>
      </div>
    </div>
  </div>
</div>
