import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { isLargeScreen, isLargeScreenFun } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { QrCodeComponent } from '../../../shared/components/qr-code/qr-code.component';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { gTDB } from '../../../shared/services/db';
import { DeviceInfoService } from '../../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { HolAndChatService } from '../hol-and-chat/hol-and-chat.service';
import { JobsService } from '../jobs/jobs.service';
import { PagesService } from '../pages.service';
import { UserPromptService } from '../user-prompts/user-prompt.service';
import { UsersService } from '../users/users.service';

declare var GuideChimp: any;
@Component({
  selector: 'gtapp-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, AfterViewInit {
  updateTask: any = 'updateJob';
  userData: any;
  staleDays: any;
  dashBoardData: any;
  subscriberStats: any;
  jobStats: any;

  isDispatchUser: any;

  isAdmin: any;
  offlineMode: boolean = false;
  isSuperUser: boolean = false;
  dialogRef: any;
  messageChannel = new MessageChannel();
  offlineData: any;
  isGuardUser: boolean;
  isGuardClockedIn: boolean;
  deferredPrompt: any;

  isSubscriberAdmin: boolean = false;
  isSubscriptionActive: boolean = false;
  bottomSectionFixedHeight: string = '180px';
  isPageLoad: boolean = false;
  systemSettings: any = {};
  largeView = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();

  userPrompts: any = [];
  updateEvenDetails: any = {};
  currentDevice: import('ngx-device-detector').DeviceInfo;
  isSOSButtonPressed: boolean = false;

  timer: any;
  pressStartTime: any;
  pressDurationThreshold: number = 3000; //  3 second
  cameraOnDevice: boolean = true;

  //missed welfare check data variables
  totalRowsMissedWelfareCheck: number = 0;
  previousMissedWelfareCheck: number = 0;
  pageSizeMissedWelfareCheck: number = 10;
  pageNumMissedWelfareCheck: number = 1;
  totalPagesMissedWelfareCheck: number = 0;
  searchParamsMissedWelfareCheck: any;
  sortKeysMissedWelfareCheck: any = ['-missed_at'];
  dateRangeMissedWelfareCheck: any;
  dateRangeValueMissedWelfareCheck: any;

  otherParamsMissedWelfareCheck: any;
  missedWelfareData: any;

  holDraftCount: number = 0;
  holReceivedCount: number = 0;

  gpsFetchTimes: any = {
    high: { data: [], stats: {} },
    highKeys: [] as string[],

    low: { data: [], stats: {} },
    lowKeys: [] as string[],
    filterValue: 0,
  };
  groupUserCount = this.pageService.getGroupUserCount();
  constructor(
    private appService: AppService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    private jobService: JobsService,
    private deviceService: DeviceDetectorService,
    private ngZone: NgZone,
    private dialogService: ModelDialogueService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private userPromptService: UserPromptService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private userService: UsersService,
    private deviceInfoService: DeviceInfoService,
    private orientationService: OrientationLockService,
    private handOverLogService: HolAndChatService,
    private pageService: PagesService,
    private swPush: SwPush
  ) {
    this.userData = this.appService.getUserData();

    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.currentDevice = this.deviceService.getDeviceInfo();
    if (!this.userData?.access) {
      this.router.navigate(['/login']);
    }
    if (this.isSuperUser === true) {
      this.router.navigate(['/gtadmin/dashboard']);
    }
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isGuardUser = this.dataCheckService.isGuardUser();
    this.isGuardClockedIn = this.dataCheckService.isGuardUserClockendIn();
    this.isSubscriberAdmin = this.dataCheckService.isSubscriberAdmin();
    this.isSubscriptionActive =
      this.dataCheckService.checkIsSubscriptionOrTestUser();
  }

  async ngAfterViewInit(): Promise<void> {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.cdr.detectChanges();
        this.spinnerService.hide();
      });
    }, 100);

    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });

    this.gpsFetchTimes.high = JSON.parse(
      localStorage.getItem('GPSFetchTimesHigh') || '[]'
    );
    this.gpsFetchTimes.highKeys = Object.keys(this.gpsFetchTimes.high);
    this.gpsFetchTimes.low = JSON.parse(
      localStorage.getItem('GPSFetchTimesLow') || '[]'
    );
    this.gpsFetchTimes.lowKeys = Object.keys(this.gpsFetchTimes.low);
  }

  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit() {
    this.spinnerService.show();

    if (this.isSubscriberAdmin && !this.isSubscriptionActive) {
      this.router.navigate([
        '/subscription',
        {
          state: 'Account Verified! Subscription Required',
        },
      ]);
    } else {
      this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
        if ('appStatus' in value) {
          this.offlineDataCheck(true);
          this.offlineMode = !value?.appStatus;
        }
      });

      if (!this.isSuperUser) {
        this.getHomePageData();
        this.getHomeScreenPrompts();
        setTimeout(() => {
          this.checkForDrafts();
        }, 10000);
      }

      this.offlineDataCheck(true);

      this.spinnerService.hide();
    }
    this.ngZone.run(() => {
      setTimeout(() => {
        this.isPageLoad = true;
        this.calculateFixedBottomHeight();
      }, 300);
    });
    if (
      this.updateTask === 'updateJob' &&
      sessionStorage.getItem('updateAvailable') === 'true'
    ) {
      this.toasterService.setMessage({
        successMessage: 'Please Wait, Updating Guard Tracker',
        errorMessage: '',
      });
      //making sure that the user is in home page and is doing nothing, to do a reload to clear the cache
      sessionStorage.removeItem('updateAvailable');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }
  async getIndexDbData(cached = true, path?: string) {
    return new Promise(async (resolve) => {
      try {
        if (this.swPush.isEnabled) {
          if (cached) {
            const result: any = await gTDB.cachedData
              .where({ shortPath: path })
              .toArray();
            resolve(result?.[0]);
          } else {
            const result: any = await gTDB.gtSavedData.toArray();
            resolve(result);
          }
        }
      } catch (error) {
        resolve([]);
      }
    });
  }
  async offlineDataCheck(bypass = false) {
    if (this.offlineMode || bypass) {
      const offlineDataDict = {
        checkpointData: await this.getIndexDbData(
          true,
          '/api/check_point/list_nearest/'
        ),
        siteData: await this.getIndexDbData(true, '/api/site/list_nearest/'),
        jobData: await this.getIndexDbData(true, '/api/job/list_nearest/'),
        qrCheckpointData: await this.getIndexDbData(
          true,
          '/api/check_point/list_qr_checkpoints/'
        ),
        savedData: await this.getIndexDbData(false),
      };

      this.formatOfflineSavedData(offlineDataDict);

      this.ngZone.run(() => {
        setTimeout(() => {
          this.isPageLoad = true;
          this.calculateFixedBottomHeight();
        }, 300);
      });
    }
  }

  closeDetailedHome(event: any) {
    if (
      [
        'scanCheckPoint',
        'addSiteComment',
        'addSitePhoto',
        'userPromptInfo',
      ].includes(this.updateTask)
    ) {
      this.updateTask = 'updateJob';
    }
    this.offlineDataCheck();

    setTimeout(() => {
      this.checkForDrafts();
    }, 1500);
  }
  checkForDrafts() {
    const tempValue: any = sessionStorage.getItem('handOverLogUpdate');
    if (tempValue > 0) {
      if (!this.holDraftCount || !this.holReceivedCount) {
        this.checkForHOL();
      }
    }
    sessionStorage.removeItem('handOverLogUpdate');
  }

  getHomePageData() {
    this.spinnerService.show();
    let param: any = { time_offset: new Date().getTimezoneOffset() };
    if (this.staleDays) {
      param.stale_days = this.staleDays;
    }

    this.jobService.getDashBoardData(param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.dashBoardData = response['data'];

        this.formatDashBoardData(this.dashBoardData);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  checkForHOL() {
    this.handOverLogService
      .getHandOverLogs({}, { drafted_count: 1 })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.holDraftCount = response?.data?.hand_over_log_drafted_count;
          this.holReceivedCount = response?.data?.hand_over_log_received;
          this.handOverLogService.updateHOLDraftCount(this.holDraftCount);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  formatDashBoardData(data: any) {
    //As route feat is not added at the moment no need to call this api.
    // this.getPendingCheckPoints();

    this.subscriberStats = data.subscriber_stats;
    this.holDraftCount = data?.hand_over_log_drafted_count;
    this.holReceivedCount = data?.hand_over_log_received;
    this.handOverLogService.updateHOLDraftCount(
      data?.hand_over_log_drafted_count
    );

    localStorage.setItem(
      'subscriberStats',
      JSON.stringify(data.subscriber_stats)
    );
    this.jobStats = data?.job_stats;

    this.isPageLoad = true;
    this.spinnerService.hide();
  }

  redirectToPage(string: any) {
    if (string === 'addReportUsers') {
      this.router.navigate(['/client'], {
        fragment: 'addReportUsers',
      });
    } else if (string == 'patrolHistory') {
      this.router.navigate(['/user-events']);
    } else if (string == 'eventLog') {
      this.router.navigate(['/user-events'], { fragment: 'eventLog' });
    } else if (string == 'myJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'jobs',
      });
    } else if (string == 'unassignedJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'unassignedJobs',
      });
    } else if (string == 'staleJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'staleJobs',
      });
    } else if (string == 'guardLocations') {
      this.router.navigate(['/guards']);
    } else if (string == 'validateQr') {
      this.router.navigate(['/checkpoints'], {
        fragment: 'validateQr',
      });
    } else if (string == 'incidents') {
      this.router.navigate(['/incidents'], {});
    } else if (string == 'handOverLog') {
      this.router.navigate(['/hand-over-log'], {});
    } else if (string == 'addHandOverLog') {
      this.router.navigate(['/add-hand-over-log'], {});
    }
  }

  formatOfflineSavedData(data: any) {
    this.offlineData = data;

    this.offlineData.savedData = this.offlineData?.savedData?.filter(
      (savedItem: any) => !savedItem?.apiCallSuccess && !savedItem?.retry_count
    );

    if (this.offlineData?.savedData?.length && !this.offlineMode) {
      this.pageService.miscSubjectParam.next({ syncData: true });
    }
  }

  openQueuedTemplate(template: TemplateRef<any>) {
    this.dialogService.open(template, {}, this.viewContainerRef);
  }

  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID
    const bottomSectionFixed = document.getElementById('fixedBtn');
    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() === '0'
          ? '180' + 'px'
          : bottomSectionFixed.offsetHeight.toString() + 'px';
      this.cdr.detectChanges();
    }
  }
  async openQRCode() {
    if (await this.hasCamera()) {
      if ((await this.checkCameraPermission()) === true) {
        this.deviceInfoService.deviceInfoSubject.next({
          permissionStatus: 'cameraDisabled',
        });
      } else {
        this.dialogRef = this.dialogService.open(QrCodeComponent, {
          data: {
            showFrom: 'home',
            cachedQRCheckpoints: this.offlineData?.qrCheckpointData?.data,
          },
        });
        this.dialogRef?.afterClosed()?.subscribe((value: any) => {
          this.offlineDataCheck();
          this.getHomePageData();
        });
      }
    } else {
      this.cameraOnDevice = false;
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Unable to access camera',
      });
    }
  }
  getHomeScreenPrompts() {
    this.userPromptService
      .getHomeUserPromptList({})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPrompts = response?.data;
        }
      });
  }

  handleSOSPress(
    isPressed: boolean,
    alertSOSTemplateRef: TemplateRef<any>,
    confirmSOSTemplate: TemplateRef<any>
  ) {
    this.isSOSButtonPressed = isPressed;
    if (isPressed) {
      this.pressStartTime = Date.now();
      this.timer = setTimeout(() => {
        this.callSOSApi(confirmSOSTemplate);
        this.isSOSButtonPressed = false;
      }, this.pressDurationThreshold);
    } else {
      clearTimeout(this.timer);
      const pressDuration = Date.now() - this.pressStartTime;
      if (pressDuration < this.pressDurationThreshold) {
        this.dialogRef = this.dialogService.open(
          alertSOSTemplateRef,
          {
            data: {},
          },
          this.viewContainerRef
        );
      }
      this.isSOSButtonPressed = false;
    }
  }

  callSOSApi(alertSOSTemplateRef: TemplateRef<any>) {
    this.spinnerService.show();
    this.profileService
      .updateUser({}, { send_sos: 1 })
      .then((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          this.dialogRef = this.dialogService.open(
            alertSOSTemplateRef,
            {
              data: {},
            },
            this.viewContainerRef
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
      });
  }
  async checkCameraPermission() {
    try {
      if (!navigator.permissions) {
        // Permissions API not supported
        return false;
      }

      const permission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      return Boolean(
        !['granted', 'prompt'].includes(permission.state) &&
          this.currentDevice.device !== 'iPhone'
      );
    } catch (error) {
      return false;
    }
  }
  hasCamera(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        resolve(false);
        return;
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoInputDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );
          resolve(videoInputDevices.length > 0);
        })
        .catch((err) => {
          resolve(false);
          throw err;
        });
    });
  }
  openMissedWelfareCheckInEvents(template?: TemplateRef<any>) {
    this.spinnerService.show();

    let body: any = {
      event_type: 'missed_welfare_checks',
      missed: 1,
    };
    if (this.dateRangeMissedWelfareCheck && window.innerWidth < 770)
      body.date_range = this.dateRangeMissedWelfareCheck;

    if (this.sortKeysMissedWelfareCheck)
      body.sort_key = this.sortKeysMissedWelfareCheck;

    if (this.searchParamsMissedWelfareCheck)
      body.search_filters = this.searchParamsMissedWelfareCheck;

    if (this.pageSizeMissedWelfareCheck) {
      body['rows'] = this.pageSizeMissedWelfareCheck;
    }
    if (this.previousMissedWelfareCheck) {
      body['previous'] = this.previousMissedWelfareCheck;
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.missedWelfareData = response['data'];

        if (this.missedWelfareData?.length) {
          if (template)
            this.dialogRef = this.dialogService.open(
              template,
              {
                data: {},
              },
              this.viewContainerRef
            );
        } else {
          this.subscriberStats.missed_welfare_checks = 0;
        }

        this.spinnerService.hide();
        this.totalRowsMissedWelfareCheck = response['total_size'];
        this.dashBoardData.missed_welfare_checks =
          this.totalRowsMissedWelfareCheck;

        this.totalPagesMissedWelfareCheck = Math.ceil(
          this.totalRowsMissedWelfareCheck / this.pageSizeMissedWelfareCheck
        );
        this.otherParamsMissedWelfareCheck = {
          paginationData: {
            tableTotalRows: this.totalRowsMissedWelfareCheck,
            tablePreviousRows: this.previousMissedWelfareCheck,
            tableRows: this.pageSizeMissedWelfareCheck,
            tablePageNum: this.pageNumMissedWelfareCheck,
            tableTotalPages: this.totalPagesMissedWelfareCheck,
          },
        };
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  updateMissedReason(missedEventData: any) {
    this.jobService
      .updateMissedEvent({
        event_id: missedEventData?.id,
        missed_reason: missedEventData?.missedReason,
      })
      .then((response: any) => {
        if (response?.status === 'success') {
          this.openMissedWelfareCheckInEvents();
        }
      });
  }
  clearValues() {
    localStorage.removeItem('GPSFetchTimesHigh');
    localStorage.removeItem('GPSFetchTimesLow');
    this.gpsFetchTimes = {};
  }
}
