import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from '../shared/shared.module';
import { CustomTooltipDirective } from './custom-tooltip.directive';
import { PagesRoutingModule } from './pages-routing.module';
import { AppChecksComponent } from './pages/app-checks/app-checks.component';
import { AppUserFeedbackComponent } from './pages/app-user-feedback/app-user-feedback.component';
import { AddEditCheckpointComponent } from './pages/checkpoints/add-edit-checkpoint/add-edit-checkpoint.component';
import { CheckpointsComponent } from './pages/checkpoints/checkpoints.component';
import { QrComponent } from './pages/checkpoints/qr/qr.component';
import { ViewCheckpointComponent } from './pages/checkpoints/view-checkpoint/view-checkpoint.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ViewClientComponent } from './pages/clients/view-client/view-client.component';
import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
import { MessageComponent } from './pages/hol-and-chat/chat/message/message.component';
import { AddEditHandOverLogComponent } from './pages/hol-and-chat/hand-over-log/hand-over-log/add-edit-hand-over-log/add-edit-hand-over-log.component';
import { HandOverLogComponent } from './pages/hol-and-chat/hand-over-log/hand-over-log/hand-over-log.component';
import { HomeComponent } from './pages/home/home.component';
import { UpdateEventsComponent } from './pages/home/update-events/update-events.component';
import { IncidentTypeComponent } from './pages/incident-type/incident-type.component';
import { AddIncidentComponent } from './pages/incidents/add-incident/add-incident.component';
import { IncidentsComponent } from './pages/incidents/incidents.component';
import { ViewIncidentComponent } from './pages/incidents/view-incident/view-incident.component';
import { AddJobComponent } from './pages/jobs/add-job/add-job.component';
import { OtherComponent } from './pages/jobs/add-job/other/other.component';
import { PatrolComponent } from './pages/jobs/add-job/patrol/patrol.component';
import { ResponseComponent } from './pages/jobs/add-job/response/response.component';
import { JobDetailsComponent } from './pages/jobs/job-details/job-details.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { PatrolDetailComponent } from './pages/jobs/patrol-detail/patrol-detail.component';
import { MissedEventsComponent } from './pages/missed-events/missed-events.component';
import { MonitoringCompanyComponent } from './pages/monitoring-company/monitoring-company.component';
import { PagesComponent } from './pages/pages.component';
import { PagesService } from './pages/pages.service';
import { ReportsComponent } from './pages/reports/reports.component';
import { ResponseTypeComponent } from './pages/response-type/response-type.component';
import { RosterScheduleComponent } from './pages/roster-schedule/roster-schedule.component';
import { RosterScheduleService } from './pages/roster-schedule/roster-schedule.service';
import { ServiceTypeComponent } from './pages/service-type/service-type.component';
import { SitesComponent } from './pages/sites/sites.component';
import { ViewSiteComponent } from './pages/sites/view-site/view-site.component';
import { SosAlertsComponent } from './pages/sos-alerts/sos-alerts.component';
import { PaymentFormComponent } from './pages/subscription/payment-form/payment-form.component';
import { SavedCardsComponent } from './pages/subscription/saved-cards/saved-cards.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { UserGroupComponent } from './pages/user-group/user-group.component';
import { AddEditUserPromptComponent } from './pages/user-prompts/add-edit-user-prompt/add-edit-user-prompt.component';
import { UserPromptsComponent } from './pages/user-prompts/user-prompts.component';
import { UserUpdateLogComponent } from './pages/user-update-log/user-update-log.component';
import { AddEditUserComponent } from './pages/users/add-edit-user/add-edit-user.component';
import { GuardsOnlineComponent } from './pages/users/guards-online/guards-online.component';
import { LicencesComponent } from './pages/users/licences/licences.component';
import { UsersComponent } from './pages/users/users.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileService } from './profile/profile.service';

@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    ProfileComponent,
    JobsComponent,
    AppChecksComponent,
    AppUserFeedbackComponent,
    CheckpointsComponent,
    ClientsComponent,
    IncidentsComponent,
    MissedEventsComponent,
    MonitoringCompanyComponent,
    ReportsComponent,
    ResponseTypeComponent,
    RosterScheduleComponent,
    SubscriptionComponent,
    UserGroupComponent,
    UsersComponent,
    UserUpdateLogComponent,
    CompanyProfileComponent,
    AddJobComponent,
    JobDetailsComponent,
    PaymentFormComponent,
    QrComponent,
    OtherComponent,
    ResponseComponent,
    PatrolComponent,
    AddEditCheckpointComponent,
    ViewClientComponent,
    ViewCheckpointComponent,
    AddEditUserComponent,
    ViewIncidentComponent,
    AddIncidentComponent,
    UpdateEventsComponent,
    GuardsOnlineComponent,
    PatrolDetailComponent,
    UserPromptsComponent,
    AddEditUserPromptComponent,
    LicencesComponent,
    SavedCardsComponent,
    SitesComponent,
    ViewSiteComponent,
    IncidentTypeComponent,
    SosAlertsComponent,
    CustomTooltipDirective,
    HandOverLogComponent,
    AddEditHandOverLogComponent,
    MessageComponent,
    ServiceTypeComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    QRCodeModule,
    DragDropModule,
  ],
  exports: [CustomTooltipDirective],
})
export class PagesModule {
  static forRoot() {
    return {
      NgModule: PagesModule,
      providers: [
        ProfileService,
        PagesService,
        RosterScheduleService,
        DatePipe,
      ],
    };
  }
}
